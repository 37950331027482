const SFConstants = {

  VERSION: "2.2.2 SNAM",
  APP_NAME: "SafetyForge",
  LANDING_PAGE: "/defaultlanding",
  SUPPORT_EMAIL_ADDRESS: "supporto@iotron.it",
  DEVELOPER_NAME: "Iotron S.r.l.",
  DEVELOPER_WEBSITE: "www.iotron.it",
  LOGIN_DELAY_TIME: 2, // secondi

  // ******************************************************************
  // REGEX PER VERIFICA FORMS
  // ******************************************************************

  // CE000001 - 05/02/2020 - Alberto Stefanutti
  // Inserito il carattere "/" tra quelli consentiti per i campi di un form.
  REGEX_CODICE: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_* ]*$/,
  REGEX_NOME: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_ ]*$/,
  REGEX_UUID_MACADDRESS: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_: ]*$/,
  REGEX_DESCRIZIONE: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_ ]*$/,
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  REGEX_DESCRIZIONE_ESTESA: /^[a-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_:()" ]*$/,
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE
  REGEX_DOMANDE_QUESTIONARI: /^[\sa-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_ ?!\s]*$/, // Versione che consente gli "a capo"
  REGEX_PWD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[*~£$%&^]).{8,}$/,
  // REGEX_DESCRIZIONE: /^[\sa-zA-Z0-9À-ÖØ-öø-ÿ'.,()/\-+_ \s]*$/, // Versione che consente gli "a capo"
  // CE000001 - 05/02/2020 - Alberto Stefanutti - FINE
  // A. Stefanutti - Campo CF ambivalente CF/P.IVA (trello https://trello.com/c/jF1Qcae0)
  REGEX_PIVA: /^([0-9]{11})*$/,
  REGEX_CF: /^([A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1})*$/,
  // A. Stefanutti - Campo CF ambivalente CF/P.IVA (trello https://trello.com/c/jF1Qcae0) - FINE
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk)
  REGEX_OREMINUTI: /^(?:[0-1]\d:[0-5]\d|[2][0-3]:[0-5]\d|24:00)$/,
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk) - FINE


  
  // ******************************************************************
  // URL delle API REST disponibili
  // ******************************************************************

  // --- GOOGLE RECAPTCHA VERIFY ---
  urlGoogleRecaptchaVerify: "/security/captcha/verify",

  // --- LOGIN ---
  urlLogin: "/servizi/accesso",

  // --- NOTIFICHE ---
  urlNotifiche_conta: "/notifiche/conta",
  urlNotifiche_lista: "/notifiche/lista",
  urlNotifiche_modifica: "/notifiche/modifica",
  opIdNotifiche_modifica: "Notifiche_edit",
  
  // --- FUNZIONALITA' ---
  urlFunzionalita_list: "/funzionalita/lista", // 1 - Lista non paginata di tutte le funzionalità
  urlFunzionalita_list_nosysadmin: "/funzionalita/nosysadmin/lista", // 2 - Lista non paginata di tutte le funzionalità con flag solosysadmin = 0
  urlFunzionalita_active_list: "/funzionalita/attive/lista", // 3 - Lista non paginata di tutte le funzionalità associate a moduli attivi
  urlFunzionalita_active_list_nosysadmin: "/funzionalita/attive/nosysadmin/lista", // 4 - Lista non paginata di tutte le funzionalità associate a moduli attivi e con flag solosysadmin = 0
  urlFunzionalita_add: "/funzionalita/aggiungi",
  urlFunzionalita_edit: "/funzionalita/modifica",
  urlFunzionalita_delete: "/funzionalita/elimina/",
  opIdFunzionalita_add: "Feature_add",
  opIdFunzionalita_edit: "Feature_edit",
  opIdFunzionalita_delete: "Feature_delete",

  // --- MODULI ---
  urlModuli_list: "/moduli/lista",
  urlModuli_add: "/moduli/aggiungi",
  urlModuli_edit: "/moduli/modifica",
  urlModuli_delete: "/moduli/elimina/",
  opIdModuli_add: "Module_add",
  opIdModuli_edit: "Module_edit",
  opIdModuli_delete: "Module_delete",
  // --- ASSOCIAZIONE MODULI-FUNZIONALITA' ---
  urlModuliFunzionalita_list: "/moduli/funzionalita/lista/u/",
  urlModuliFunzionalita_edit: "/moduli/funzionalita/modifica",
  opIdModuliFunzionalita_edit: "ModuleFeatures_edit",

  // --- RUOLI ---
  urlRuoli_list: "/ruoli/lista", // Restituisce tutti i ruoli
  urlRuoli_list_nosysadmin: "/ruoli/nosysadmin/lista", // NON restituisce i ruoli solosysadmin
  urlRuoli_list_noreserved: "/ruoli/noreserved/lista", // NON restituisce i ruoli riservati
  urlRuoli_list_nosysadmin_noreserved: "/ruoli/nosysadmin/noreserved/lista", // NON restituisce i ruoli solosysadmin e i ruoli riservati
  urlRuoli_add: "/ruoli/aggiungi",
  urlRuoli_edit: "/ruoli/modifica",
  urlRuoli_delete: "/ruoli/elimina/",
  opIdRuoli_add: "Role_add",
  opIdRuoli_edit: "Role_edit",
  opIdRuoli_delete: "Role_delete",
  // --- ASSOCIAZIONE RUOLI-FUNZIONALITA' ---
  urlRuoliFunzionalita_list: "/ruoli/funzionalita/lista/u/",
  urlRuoliFunzionalita_multipli_list: "/ruoli/funzionalita/lista/multipli", // Restituisce la lista delle funzionalità sulla base dei ruoli passati
  urlRuoliFunzionalita_edit: "/ruoli/funzionalita/modifica",
  opIdRuoliFunzionalita_edit: "RoleFeatures_edit",

  // --- PROFILI ---
  urlProfili_list: "/profili/lista", // Restituisce tutti i profili
  urlProfili_list_nosysadmin: "/profili/nosysadmin/lista", // NON restituisce i profili solosysadmin
  urlProfili_list_noreserved: "/profili/noreserved/lista", // NON restituisce i profili riservati
  urlProfili_list_nosysadmin_noreserved: "/profili/nosysadmin/noreserved/lista", // NON restituisce i profili solosysadmin e i profili riservati
  urlProfili_add: "/profili/aggiungi",
  urlProfili_edit: "/profili/modifica",
  urlProfili_delete: "/profili/elimina/",
  opIdProfili_add: "Profile_add",
  opIdProfili_edit: "Profile_edit",
  opIdProfili_delete: "Profile_delete",
  // --- ASSOCIAZIONE PROFILI-RUOLI ---
  urlProfiliRuoli_list: "/profili/ruoli/lista/u/",
  urlProfiliRuoli_edit: "/profili/ruoli/modifica",
  opIdProfiliRuoli_edit: "ProfileRoles_edit",

  // --- SOCIETA ---
  urlSocieta_list: "/societa/lista",
  urlSocieta_list_nosysadmin: "/societa/nosysadmin/lista",
  urlSocieta_assegnabili_list: "/societa/lista/assegnabili/attivita/",
  urlSocieta_assegnabili_list_nosysadmin: "/societa/nosysadmin/lista/assegnabili/attivita/",
  urlSocieta_senzaac_list: "/societa/lista/assegnabili/areacenter",
  urlSocieta_senzaac_list_nosysadmin: "/societa/nosysadmin/lista/assegnabili/areacenter",
  urlSocieta_add: "/societa/aggiungi",
  urlSocieta_edit: "/societa/modifica",
  urlSocieta_delete: "/societa/elimina/",
  opIdSocieta_add: "Societa_add",
  opIdSocieta_edit: "Societa_edit",
  opIdSocieta_delete: "Societa_delete",
  // --- ASSOCIAZIONE SOCIETA-SITI ---
  urlSocietaSiti_list: "/societa/lista/siti/d/",
  urlSocietaSiti_edit: "/societa/siti/modifica",
  opIdSocietaSiti_edit: "SocietaSiti_edit",
  // --- ASSOCIAZIONE SOCIETA-MEZZI ---
  urlSocietaMezzi_list: "/societa/lista/mezzi/d/",
  urlSocietaMezzi_edit: "/societa/mezzi/modifica",
  opIdSocietaMezzi_edit: "SocietaMezzi_edit",
  // --- ASSOCIAZIONE SOCIETA-ATTREZZATURE ---
  urlSocietaAttrezzature_list: "/societa/lista/attrezzature/d/",
  urlSocietaAttrezzature_edit: "/societa/attrezzature/modifica",
  opIdSocietaAttrezzature_edit: "SocietaAttrezzature_edit",

  // --- UTENTI ---
  urlUtenti_list: "/utenti/lista",
  urlUtenti_list_concartacompetenze_zone: "/utenti/lista/concartacompetenze/conzone",
  urlUtenti_list_nosysadmin: "/utenti/nosysadmin/lista",
  // A. Stefanutti - Filtraggio per zone nella sezione operatività - utenti (aka "scheda FBI") - Trello https://trello.com/c/AZGEY6WP.
  urlUtenti_list_zone: "/utenti/lista/conzone",
  urlUtenti_list_nosysadmin_zone: "/utenti/nosysadmin/lista/conzone",
  // A. Stefanutti - Filtraggio per zone nella sezione operatività - utenti (aka "scheda FBI") - FINE.
  urlUtenti_list_nosysadmin_concartacompetenze_zone: "/utenti/nosysadmin/lista/concartacompetenze/conzone",
  urlUtenti_add: "/utenti/aggiungi",
  urlUtenti_add_nosysadmin: "/utenti/nosysadmin/aggiungi",
  urlUtenti_edit: "/utenti/modifica",
  urlUtenti_delete: "/utenti/elimina/",
  // A. Stefanutti - Rendere funzionante l'import massivo utenti da CSV (https://trello.com/c/ujmCB52D)
  urlUtenti_import_csv: "/utenti/import/csv",
  urlUtenti_import_csv_nosysadmin: "/utenti/nosysadmin/import/csv",
  // A. Stefanutti - Rendere funzionante l'import massivo utenti da CSV (https://trello.com/c/ujmCB52D) - FINE
  urlPassword_change: "/utenti/cambiapwd",
  urlPassword_reset: "/utenti/resetpwd",
  // A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD)
  urlPassword_changeStep1: "/utenti/cambiapwdstep1",
  urlPassword_changeStep2: "/utenti/cambiapwdstep2",
  urlPassword_changeStep3: "/utenti/cambiapwdstep3",
  // A. Stefanutti - Nuova gestione password dimenticata/reset (trello https://trello.com/c/y5GEmknD) - FINE
  opIdUtenti_add: "User_add",
  opIdUtenti_add_nosysadmin: "User_add_nosysadmin",
  opIdUtenti_edit: "User_edit",
  opIdUtenti_delete: "User_delete",
  opIdPassword_change: "Password_change",
  opIdPassword_reset: "Password_reset",
  urlUtenti_dettaglio: "/utenti/dettaglio/u/",
  // A. Stefanutti - Gestione verbale di consegna DPI via web (trello https://trello.com/c/JpM7AcDT e correlate).
  urlUpdateVerbale: "/allegati/verbaliconsegna/sostituisci",
  opIdUpdateVerbale: "VerbaleConsegna_replace",
  // A. Stefanutti - Gestione verbale di consegna DPI via web - FINE
  // A. Stefanutti - Matrice Operatori-DPI (https://trello.com/c/C2vW3zNf)
  urlUtenti_dettaglio_light: "//utenti/dettaglio/light/byzone",
  // A. Stefanutti - Matrice Operatori-DPI (https://trello.com/c/C2vW3zNf) - FINE
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk)
  urlUtenti_assegnaTurni: "/turnilavoro/assegna",
  opIdUtenti_assegnaTurni: "AssegnazioniTurniLavoro_add",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk) - FINE

  // --- ASSOCIAZIONE UTENTI-ZONE
  urlUtentiZone_list: "/utenti/zone/lista/u/",
  urlUtentiZone_edit: "/utenti/zone/modifica",
  opIdUtentiZone_edit: "UtentiZone_edit",
  // --- ASSOCIAZIONE UTENTI-DPI
  urlUtentiDpi_list: "/utenti/dpi/lista/u/",
  urlUtentiDpi_edit: "/utenti/dpi/modifica",
  // A. Stefanutti - Gestione verbale di consegna DPI via web (trello https://trello.com/c/JpM7AcDT e correlate).
  urlUtentiDpi_edit_converbale: "/utenti/dpi/modifica/converbale",
  // A. Stefanutti - Gestione verbale di consegna DPI via web - FINE
  opIdUtentiDpi_edit: "UserPpes_edit",
  // --- ASSOCIAZIONE UTENTI-TIPI DI OPERAZIONE
  urlUtentiTipiOperazione_list: "/utenti/tipioperazione/lista/u/",
  urlUtentiTipiOperazione_edit: "/utenti/tipioperazione/modifica",
  opIdUtentiTipiOperazione_edit: "UtentiTipiOperazione_edit",
  // --- ASSOCIAZIONE UTENTI-LIVELLI NOTIFICA
  urlUtentiLivelliNotifica_list: "/utenti/livellinotifica/lista/u/",
  urlUtentiLivelliNotifica_edit: "/utenti/livellinotifica/modifica",
  opIdUtentiLivelliNotifica_edit: "UtentiLivelliNotifica_edit",
  // --- ASSOCIAZIONE UTENTI-CANALI NOTIFICA
  urlUtentiCanaliNotifica_list: "/utenti/canalinotifica/lista/u/",
  urlUtentiCanaliNotifica_edit: "/utenti/canalinotifica/modifica",
  opIdUtentiCanaliNotifica_edit: "UtentiCanaliNotifica_edit",

  // --- CARTE COMPETENZE ---
  urlCartecompetenze_list: "/cartecompetenze/lista",
  urlCartacompetenze_renew: "/cartecompetenze/rinnovo/",
  urlCartacompetenzeCompetenze_list: "/cartecompetenze/competenze/lista/u/",
  opIdCartacompetenze_renew: "CartaCompetenze_renew",
  opIdCartacompetenzeCompetenze_list: "CartaCompetenzeCompetenze_edit",

  // --- COMPETENZE ---
  urlCompetenze_list: "/competenze/lista",
  urlCompetenze_add: "/competenze/aggiungi",
  urlCompetenze_edit: "/competenze/modifica",
  urlCompetenze_delete: "/competenze/elimina/",
  opIdCompetenze_add: "Competenze_add",
  opIdCompetenze_edit: "Competenze_edit",
  opIdCompetenze_delete: "Competenze_delete",

  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti
  // Differenziazione dell'assetto di riferimento di ogni utente in funzione della sua mansione.
  // --- IDONEITA' ---
  urlIdoneita_list: "/idoneita/lista",
  // A. Stefanutti - Matrice Idoneità - Tipi di DPI
  urlIdoneitaConTipiDpi_list: "/idoneita/contipidpi/lista",
  // A. Stefanutti - Matrice Idoneità - Tipi di DPI - FINE
  urlIdoneita_add: "/idoneita/aggiungi",
  urlIdoneita_edit: "/idoneita/modifica",
  urlIdoneita_delete: "/idoneita/elimina/",
  urlIdoneitaRischi_list: "/idoneita/rischi/lista/u/",
  urlIdoneitaRischi_edit: "/idoneita/rischi/modifica",
  // A. Stefanutti - POC Olivetti TIM - Req New (06/07/22) - Filtro attività per idoneità (trello https://trello.com/c/ZcWJE6JE)
  urlIdoneitaCompetenze_list: "/idoneita/competenze/lista/u/",
  urlIdoneitaCompetenze_edit: "/idoneita/competenze/modifica",
  // A. Stefanutti - POC Olivetti TIM - Req New (06/07/22) - Filtro attività per idoneità (trello https://trello.com/c/ZcWJE6JE) - FINE
  opIdIdoneita_add: "Idoneita_add",
  opIdIdoneita_edit: "Idoneita_edit",
  opIdIdoneita_delete: "Idoneita_delete",
  opIdIdoneitaRischi_edit: "IdoneitaRischi_edit",
  // A. Stefanutti - POC Olivetti TIM - Req New (06/07/22) - Filtro attività per idoneità (trello https://trello.com/c/ZcWJE6JE)
  opIdIdoneitaCompetenze_edit: "IdoneitaCompetenze_edit",
  // A. Stefanutti - POC Olivetti TIM - Req New (06/07/22) - Filtro attività per idoneità (trello https://trello.com/c/ZcWJE6JE) - FINE

  // --- RISCHI ---
  urlRischi_list: "/rischi/lista",
  urlRischi_add: "/rischi/aggiungi",
  urlRischi_edit: "/rischi/modifica",
  urlRischi_delete: "/rischi/elimina/",
  opIdRischi_add: "Rischi_add",
  opIdRischi_edit: "Rischi_edit",
  opIdRischi_delete: "Rischi_delete",
  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti - FINE

  // --- ATTRIBUTI ---
  urlAttributi_list: "/attributi/lista",
  urlAttributi_add: "/attributi/aggiungi",
  urlAttributi_edit: "/attributi/modifica",
  urlAttributi_delete: "/attributi/elimina/",
  opIdAttributi_add: "Attributo_add",
  opIdAttributi_edit: "Attributo_edit",
  opIdAttributi_delete: "Attributo_delete",

  // --- TASKS ---
  urlTasks_list: "/tasks/lista",
  urlTasks_add: "/tasks/aggiungi",
  urlTasks_edit: "/tasks/modifica",
  urlTasks_delete: "/tasks/elimina/",
  opIdTasks_add: "Tasks_add",
  opIdTasks_edit: "Tasks_edit",
  opIdTasks_delete: "Tasks_delete",

  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_002, REQ_FUN_DPIATTR_003
  // REQ_TEC_DPIATTR_WEB_003, REQ_TEC_DPIATTR_WEB_004
  // Creare Amministrazione classi con wizard e associazione agli attributi.
  // --- CLASSI ---
  urlClassi_list: "/classi/lista",
  urlClassi_add: "/classi/aggiungi",
  urlClassi_edit: "/classi/modifica",
  urlClassi_delete: "/classi/elimina/",
  opIdClassi_add: "Classi_add",
  opIdClassi_edit: "Classi_edit",
  opIdClassi_delete: "Classi_delete",
  // --- ASSOCIAZIONE CLASSI-ATTRIBUTI ---
  urlClassiAttributi_list: "/classi/attributi/lista/u/",
  urlClassiAttributi_edit: "/classi/attributi/modifica",
  opIdClassiAttributi_edit: "ClassiAttributi_edit",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_008, REQ_FUN_DPIATTR_009, REQ_FUN_DPIATTR_010
  // REQ_TEC_DPIATTR_WEB_009, REQ_TEC_DPIATTR_WEB_010, REQ_TEC_DPIATTR_WEB_011
  // Creare Amministrazione modelli.
  // --- MODELLI ---
  urlModelli_list: "/modelli/lista",
  urlModelli_list_conallegati: "/modelli/conallegati/lista",
  urlModelli_add: "/modelli/aggiungi",
  urlModelli_edit: "/modelli/modifica",
  urlModelli_delete: "/modelli/elimina/",
  opIdModelli_add: "Modelli_add",
  opIdModelli_edit: "Modelli_edit",
  opIdModelli_delete: "Modelli_delete",
  // --- ASSOCIAZIONE MODELLI-ALLEGATI ---
  urlModelliAllegati_add: "/allegati/modelli/aggiungi",
  urlModelliAllegati_list: "/allegati/modelli/",
  urlModelliAllegati_delete: "/allegati/modelli/elimina",
  urlModelliAllegati_deletemany: "/allegati/modelli/elimina/multipli",
  opIdModelliAllegati_add: "AttachModelli_add",
  opIdModelliAllegati_delete: "AttachModelli_delete",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // --- TIPI DI ATTREZZATURA ---
  urlTipiAttrezzatura_list: "/tipiattrezzatura/lista",
  urlTipiAttrezzatura_add: "/tipiattrezzatura/aggiungi",
  urlTipiAttrezzatura_edit: "/tipiattrezzatura/modifica",
  urlTipiAttrezzatura_delete: "/tipiattrezzatura/elimina/",
  opIdTipiAttrezzatura_add: "TipoAttrezzatura_add",
  opIdTipiAttrezzatura_edit: "TipoAttrezzatura_edit",
  opIdTipiAttrezzatura_delete: "TipoAttrezzatura_delete",
  // --- ASSOCIAZIONE TIPI DI ATTREZZATURA-ATTRIBUTI ---
  urlTipiAttrezzaturaAttributi_list: "/tipiattrezzatura/attributi/lista/u/",
  urlTipiAttrezzaturaAttributi_edit: "/tipiattrezzatura/attributi/modifica",
  opIdTipiAttrezzaturaAttributi_edit: "TipoAttrezzaturaAttributi_edit",

  // --- ATTREZZATURE ---
  urlAttrezzature_list: "/attrezzature/lista",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  urlAttrezzature_list_usabili: "/attrezzature/lista/usabili",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  urlAttrezzature_add: "/attrezzature/aggiungi",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" (trello https://trello.com/c/y9763xJ7).
  urlAttrezzature_stock: "/attrezzature/genera",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" - FINE
  // A. Stefanutti - Importazione massiva delle attrezzature tramite file CSV (trello https://trello.com/c/yyuJ5yk5)
  urlAttrezzature_import_csv: "/attrezzature/import/csv",
  // A. Stefanutti - Importazione massiva delle attrezzature tramite file CSV - FINE
  urlAttrezzature_edit: "/attrezzature/modifica",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  urlAttrezzature_dismiss: "/attrezzature/dismiss",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  urlAttrezzature_delete: "/attrezzature/elimina/",
  urlAttrezzatureZone_list: "/attrezzature/lista/zone/u/",
  urlAttrezzatureZone_edit: "/attrezzature/zone/modifica",
  // A. Stefanutti - POC OLIVETTI TIM REQ 8 Evidenziare attrezzature con dispositivo associato (trello https://trello.com/c/A6Cyp0Gi)
  urlAttrezzaturaDispositivi_list: "/attrezzature/listadispositivi/a/",
  // A. Stefanutti - POC OLIVETTI TIM REQ 8 Evidenziare attrezzature con dispositivo associato (trello https://trello.com/c/A6Cyp0Gi) - FINE
  opIdAttrezzature_add: "Attrezzatura_add",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" (trello https://trello.com/c/y9763xJ7).
  opIdAttrezzature_stock: "Attrezzatura_genera",
  // A. Stefanutti - Approvvigionamento di attrezzature non "identificate" - FINE
  opIdAttrezzature_edit: "Attrezzatura_edit",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  opIdAttrezzature_dismiss: "Attrezzatura_dismiss",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  opIdAttrezzature_delete: "Attrezzatura_delete",
  opIdAttrezzatureZone_edit: "AttrezzatureZone_edit",

  // --- ZONE ---
  urlZone_list: "/zone/lista",
  urlZone_add: "/zone/aggiungi",
  urlZone_edit: "/zone/modifica",
  urlZone_delete: "/zone/elimina/",
  opIdZone_add: "Zone_add",
  opIdZone_edit: "Zone_edit",
  opIdZone_delete: "Zone_delete",

  // --- ORDINI (ADMIN) ---
  urlAdminOrdini_list: "/ordini/lista",
  urlAdminOrdini_add: "/ordini/aggiungi",
  urlAdminOrdini_edit: "/ordini/modifica",
  urlAdminOrdini_delete: "/ordini/elimina/",
  opIdAdminOrdini_add: "OrderViaWeb_add",
  opIdAdminOrdini_edit: "OrderViaWeb_edit",
  opIdAdminOrdini_delete: "OrderViaWeb_delete",

  // --- LICENZA E PARAMETRI CONFIGURAZIONE ---
  urlStatusLicenza: "/sistema/licenza/status",
  urlUploadLicenza: "/sistema/licenza/carica",
  urlParametriConfigurazione_list: "/parametri/lista",
  urlParametriConfigurazione_add: "/parametri/aggiungi",
  urlParametriConfigurazione_edit: "/parametri/modifica",
  urlParametriConfigurazione_delete: "/parametri/elimina/",
  urlSync: "/sistema/sincronizza/",
  opIdUploadLicenza: "Licenza_Load",
  opIdParametriConfigurazione_add: "ParametriConfig_add",
  opIdParametriConfigurazione_edit: "ParametriConfig_edit",
  opIdParametriConfigurazione_delete: "ParametriConfig_del",
  
  // --- STABILIMENTI ---
  urlStabilimenti_list: "/stabilimenti/lista",
  urlStabilimenti_add: "/stabilimenti/aggiungi",
  urlStabilimenti_edit: "/stabilimenti/modifica",
  urlStabilimenti_delete: "/stabilimenti/elimina/",
  opIdStabilimenti_add: "Stabilimenti_add",
  opIdStabilimenti_edit: "Stabilimenti_edit",
  opIdStabilimenti_delete: "Stabilimenti_del",

  // --- UBICAZIONI ---
  urlUbicazioni_list: "/ubicazioni/lista",
  urlUbicazioni_add: "/ubicazioni/aggiungi",
  urlUbicazioni_edit: "/ubicazioni/modifica",
  urlUbicazioni_delete: "/ubicazioni/elimina/",
  opIdUbicazioni_add: "Ubicazioni_add",
  opIdUbicazioni_edit: "Ubicazioni_edit",
  opIdUbicazioni_delete: "Ubicazioni_delete",

  // --- AREACENTERS ---
  urlAreacenter_list: "/areacenter/lista",
  urlAreacenter_add: "/areacenter/aggiungi",
  urlAreacenter_edit: "/areacenter/modifica",
  urlAreacenter_delete: "/areacenter/elimina/",
  opIdAreacenter_add: "AreaCenter_add",
  opIdAreacenter_edit: "AreaCenter_edit",
  opIdAreacenter_delete: "AreaCenter_delete",

  // --- GESTIONE ORDINI ---
  urlOrdini_list: "/ordini/lista/recenti",
  urlOrdini_assegna: "/ordini/assegna",
  urlOrdini_accetta: "/ordini/accetta",
  urlOrdini_rifiuta: "/ordini/rifiuta",
  urlOrdini_storicoordine: "/ordini/storico/",
  urlOrdini_attrezzatureordine: "/attrezzature/presaincarico/lista/",
  urlOrdini_attrezzatureinterventiordine: "/attrezzature/presaincarico/coninterventi/lista/",
  opIdOrdini_assegna: "StatusOrdine_assigned",
  opIdOrdini_accetta: "StatusOrdine_accepted",
  opIdOrdini_rifiuta: "StatusOrdine_refused",

  // EVOLUTIVE SMART ASSET P1 - A. Stefanutti
  // Vista interventi
  // OPERATIVITA' - INTERVENTI
  urlInterventi_filters: "/interventi/listaoperativa/filtri", // Dati per popolare il form di filtraggio degli interventi
  urlInterventi_search: "/interventi/listaoperativa",
  // EVOLUTIVE SMART ASSET P1 - A. Stefanutti - FINE

  // --- TIPI DI SCHEDA INTERVENTO ---
  urlTipiSchedaIntervento_list: "/tipischeda/lista",
  urlTipiSchedaIntervento_add: "/tipischeda/aggiungi",
  urlTipiSchedaIntervento_edit: "/tipischeda/modifica",
  urlTipiSchedaIntervento_delete: "/tipischeda/elimina/",
  opIdTipiSchedaIntervento_add: "TipoScheda_add",
  opIdTipiSchedaIntervento_edit: "TipoScheda_edit",
  opIdTipiSchedaIntervento_delete: "TipoScheda_delete",
  // --- ASSOCIAZIONE TIPI DI SCHEDA INTERVENTO-TASKS ---
  urlTipiSchedaInterventoTasks_list: "/tasks/tiposcheda/lista/u/",
  urlTipiSchedaInterventoTasks_edit: "/tasks/tiposcheda/modifica",
  opIdTipiSchedaInterventoTasks_edit: "TipoSchedaTasks_edit",

  // --- PERIODICITA' ---
  urlPeriodicita_list: "/periodicita/lista",
  urlPeriodicita_add: "/periodicita/aggiungi",
  urlPeriodicita_edit: "/periodicita/modifica",
  urlPeriodicita_delete: "/periodicita/elimina/",
  opIdPeriodicita_add: "Periodicita_add",
  opIdPeriodicita_edit: "Periodicita_edit",
  opIdPeriodicita_delete: "Periodicita_delete",

  // --- CLIENTI ---
  urlStabilimentiBySocieta_list: "/stabilimenti/lista/tutti/",
  urlAttrezzatureByStabilimento_list: "/attrezzature/lista/tutti/",

  // --- STATISTICHE ---
  urlStatisticheOrdiniByStatus: "/ordini/status",
  urlStatisticheOrdiniByInterventi: "/ordini/status/interventi",
  
  // --- CANALI NOTIFICA ---
  urlCanaliNotifica_list: "/canalinotifica/lista",
  urlCanaliNotifica_add: "/canalinotifica/aggiungi",
  urlCanaliNotifica_edit: "/canalinotifica/modifica",
  urlCanaliNotifica_delete: "/canalinotifica/elimina/",
  opIdCanaliNotifica_add: "CanaliNotifica_add",
  opIdCanaliNotifica_edit: "CanaliNotifica_edit",
  opIdCanaliNotifica_delete: "CanaliNotifica_delete",

  // --- LIVELLI NOTIFICA ---
  urlLivelliNotifica_list: "/livellinotifica/lista",
  urlLivelliNotifica_add: "/livellinotifica/aggiungi",
  urlLivelliNotifica_edit: "/livellinotifica/modifica",
  urlLivelliNotifica_delete: "/livellinotifica/elimina/",
  opIdLivelliNotifica_add: "LivelliNotifica_add",
  opIdLivelliNotifica_edit: "LivelliNotifica_edit",
  opIdLivelliNotifica_delete: "LivelliNotifica_delete",

  // --- TIPI DI OPERAZIONE ---
  urlTipiOperazione_list: "/tipioperazione/lista",
  urlTipiOperazione_list_nosysadmin: "/tipioperazione/nosysadmin/lista",
  urlTipiOperazione_add: "/tipioperazione/aggiungi",
  urlTipiOperazione_edit: "/tipioperazione/modifica",
  urlTipiOperazione_delete: "/tipioperazione/elimina/",
  opIdTipiOperazione_add: "ActionKind_add",
  opIdTipiOperazione_edit: "ActionKind_edit",
  opIdTipiOperazione_delete: "ActionKind_delete",
  // --- ASSOCIAZIONE TIPI DI OPERAZIONE-CANALI DI NOTIFICA
  urlTipiOperazioneCanaliNotifica_list: "/tipioperazione/lista/canalinotifica/u/",
  urlTipiOperazioneCanaliNotifica_edit: "/tipioperazione/canalinotifica/modifica",
  opIdTipiOperazioneCanaliNotifica_edit: "TipiOperazCanaliNotifica_edit",

  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_001
  // REQ_TEC_DPIATTR_WEB_001
  // Modificare Amministrazione tipi campo (dato) - lasciare SOLO vista tabellare
  // - Rinominato tutto in "Dato" invece di "Campo"
  // --- TIPI DI DATO ---
  urlTipiDato_list: "/tipidato/lista",
  urlTipiDato_add: "/tipidato/aggiungi",
  urlTipiDato_edit: "/tipidato/modifica",
  urlTipiDato_delete: "/tipidato/elimina/",
  opIdTipiDato_add: "TipiDato_add",
  opIdTipiDato_edit: "TipiDato_edit",
  opIdTipiDato_delete: "TipiDato_delete",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // --- TIPI DI ATTIVITA' ---
  urlTipiAttivita_list: "/tipiattivita/lista",
  urlTipiAttivita_add: "/tipiattivita/aggiungi",
  urlTipiAttivita_edit: "/tipiattivita/modifica",
  urlTipiAttivita_delete: "/tipiattivita/elimina/",
  opIdTipiAttivita_add: "TipiAttivita_add",
  opIdTipiAttivita_edit: "TipiAttivita_edit",
  opIdTipiAttivita_delete: "TipiAttivita_delete",

  // --- FBI SOCIETA ---
  urlDatiSocietari: "/clienti/datisocietari/",

  // --- REGISTRO DEI CONTROLLI ---
  urlRegistroControlli: "/stabilimenti/registrocontrolli/",

  // --- SCHEDA INTERVENTO ---
  urlSchedaIntervento: "/schede/intervento/",

  // --- TIPI DI ALLARME ---
  urlTipiAllarme_list: "/tipiallarme/lista",
  urlTipiAllarme_add: "/tipiallarme/aggiungi",
  urlTipiAllarme_edit: "/tipiallarme/modifica",
  urlTipiAllarme_delete: "/tipiallarme/elimina/",
  opIdTipiAllarme_add: "TipiAllarme_add",
  opIdTipiAllarme_edit: "TipiAllarme_edit",
  opIdTipiAllarme_delete: "TipiAllarme_delete",

  // --- SOLUZIONI ALLARME ---
  urlSoluzioniAllarme_list: "/soluzioni/lista",
  urlSoluzioniAllarme_add: "/soluzioni/aggiungi",
  urlSoluzioniAllarme_edit: "/soluzioni/modifica",
  urlSoluzioniAllarme_delete: "/soluzioni/elimina/",
  opIdSoluzioniAllarme_add: "Soluzioni_add",
  opIdSoluzioniAllarme_edit: "Soluzioni_edit",
  opIdSoluzioniAllarme_delete: "Soluzioni_delete",

  // --- LIVELLI LOG ---
  urlLivelliLog_list: "/livellilog/lista",
  urlLivelliLog_add: "/livellilog/aggiungi",
  urlLivelliLog_edit: "/livellilog/modifica",
  urlLivelliLog_delete: "/livellilog/elimina/",
  opIdLivelliLog_add: "LogLevel_add",
  opIdLivelliLog_edit: "LogLevel_edit",
  opIdLivelliLog_delete: "LogLevel_delete",

  // --- PROTOCOLLI ---
  urlProtocolli_list: "/protocolli/lista",
  urlProtocolli_add: "/protocolli/aggiungi",
  urlProtocolli_edit: "/protocolli/modifica",
  urlProtocolli_delete: "/protocolli/elimina/",
  opIdProtocolli_add: "Protocol_add",
  opIdProtocolli_edit: "Protocol_edit",
  opIdProtocolli_delete: "Protocol_delete",

  // --- TECNOLOGIE ---
  urlTecnologie_list: "/tecnologie/lista",
  urlTecnologie_add: "/tecnologie/aggiungi",
  urlTecnologie_edit: "/tecnologie/modifica",
  urlTecnologie_delete: "/tecnologie/elimina/",
  opIdTecnologie_add: "Technology_add",
  opIdTecnologie_edit: "Technology_edit",
  opIdTecnologie_delete: "Technology_delete",

  // --- TIPI DI ASSETTO
  urlTipiAssetto_list: "/tipiassetto/lista",
  urlTipiAssetto_add: "/tipiassetto/aggiungi",
  urlTipiAssetto_edit: "/tipiassetto/modifica",
  urlTipiAssetto_delete: "/tipiassetto/elimina/",
  opIdTipiAssetto_add: "PpeLayoutKind_add",
  opIdTipiAssetto_edit: "PpeLayoutKind_edit",
  opIdTipiAssetto_delete: "PpeLayoutKind_delete",

  // --- TIPI DI LAVORO ---
  urlTipiLavoro_list: "/tipilavoro/lista",
  urlTipiLavoro_add: "/tipilavoro/aggiungi",
  urlTipiLavoro_edit: "/tipilavoro/modifica",
  urlTipiLavoro_delete: "/tipilavoro/elimina/",
  opIdTipiLavoro_add: "JobKind_add",
  opIdTipiLavoro_edit: "JobKind_edit",
  opIdTipiLavoro_delete: "JobKind_delete",

  // --- DPI ---
  urlDpi_list: "/dpi/lista/tutti",
  urlDpi_free_list: "/dpi/lista/tutti/liberi",
  // A. Stefanutti - 26/04/2021 - Filtro DPI liberi per idoneità
  urlDpi_free_idoneita_list: "/dpi/lista/peridoneita/liberi",
  // A. Stefanutti - 26/04/2021 - Filtro DPI liberi per idoneità - FINE
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  urlDpi_free_idoneita_list_nonscad_nondism: "/dpi/lista/peridoneita/liberi/usabili",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE
  opIdDpi_free_list: "Ppe_free_list",

  // --- ASSETTI ---
  urlAssetti_list: "/assetti/lista",
  urlAssetti_add: "/assetti/aggiungi",
  urlAssetti_edit: "/assetti/modifica",
  urlAssetti_delete: "/assetti/elimina/",
  urlAssettiTipiDpi_list: "/assetti/lista/tipidpi/a/",
  urlAssettiTipiDpi_edit: "/assetti/tipidpi/modifica",
  opIdAssetti_add: "PpeLayout_add",
  opIdAssetti_edit: "PpeLayout_edit",
  opIdAssetti_delete: "PpeLayout_delete",
  opIdAssettiTipiDpi_edit: "PpeLayoutPpeKinds_edit",

  // --- TIPI DI DPI ---
  urlTipiDpi_list: "/tipidpi/lista",

  // --- TIPI DI TELEMETRIA ---
  urlTipiTelemetria_list: "/tipitelemetria/lista",
  urlTipiTelemetria_add: "/tipitelemetria/aggiungi",
  urlTipiTelemetria_edit: "/tipitelemetria/modifica",
  urlTipiTelemetria_delete: "/tipitelemetria/elimina/",
  opIdTipiTelemetria_add: "TipiTelemetria_add",
  opIdTipiTelemetria_edit: "TipiTelemetria_edit",
  opIdTipiTelemetria_delete: "TipiTelemetria_delete",

  // --- TIPI DI DISPOSITIVO ---
  urlTipiDispositivo_list: "/tipidispositivo/lista",
  urlTipiDispositivo_add: "/tipidispositivo/aggiungi",
  urlTipiDispositivo_edit: "/tipidispositivo/modifica",
  urlTipiDispositivo_delete: "/tipidispositivo/elimina/",
  urlTipiDispositivoTipiTelemetria_list: "/tipidispositivo/tipitelemetria/lista/tutti/",
  urlTipiDispositivoTipiTelemetria_edit: "/tipidispositivo/tipitelemetria/modifica",
  opIdTipiDispositivo_add: "DeviceKind_add",
  opIdTipiDispositivo_edit: "DeviceKind_edit",
  opIdTipiDispositivo_delete: "DeviceKind_delete",
  opIdTipiDispositivoTipiTelemetria_edit: "TipoDispTipiTelemetria_edit",

  // --- DISPOSITIVI ---
  // urlDevicesScanFilteredList: "/dispositivi/scan/lista/filtri", // Dati per popolare il form di filtraggio del ciclo di vita
  // opIdDevicesScanFilteredList: "DevicesScanFilteredList",
  // urlDevicesScanFilteredList_nosysadmin: "/dispositivi/scan/nosysadmin/lista/filtri/s/", // Dati per popolare il form di filtraggio del ciclo di vita, filtrati per società
  // opIdDevicesScanFilteredList_nosysadmin: "DevicesScanFilteredList_nosys",
  // urlDevicesScanAnomaliesFilteredList_nosysadmin: "/dispositivi/scan/nonrilevati/nosysadmin/lista/filtri/s/", // Dati per popolare il form di filtraggio delle anomalie del ciclo di vita, filtrati per società
  // opIdDevicesScanAnomaliesFilteredList_nosysadmin: "DevicesScanAnomFiltList_nosys",
  // urlDevicesScanAnomaliesFilteredList: "/dispositivi/scan/nonrilevati/lista/filtri", // Dati per popolare il form di filtraggio delle anomalie del ciclo di vita
  // opIdDevicesScanAnomaliesFilteredList: "DevicesScanAnomFiltList",
  // urlDevicesScanAnalisys: "/dispositivi/scan/analisi", // Analisi del ciclo di vita (rilevati/non rilevati) filtrato mediante form
  // opIdDevicesScanAnalisys: "DevicesScanAnalisys",
  // urlDevicesScanSearch: "/dispositivi/scan/cerca", // Elenco registrazioni del ciclo di vita filtrato mediante form
  // opIdDevicesScanSearch: "DevicesScanSearch",
  urlDispositivi_list: "/dispositivi/lista/tutti",
  urlDispositivi_list_filtered: "/dispositivi/lista/tutti/filtrata",
  urlDispositivi_add: "/dispositivi/aggiungi",
  urlDispositivi_add_streamsets: "",
  // A. Stefanutti - Importazione massiva dei dispositivi tramite file CSV (trello https://trello.com/c/UQ06QJ6S)
  urlDispositivi_import_csv: "/dispositivi/import/csv",
  // A. Stefanutti - Importazione massiva dei dispositivi tramite file CSV (trello https://trello.com/c/UQ06QJ6S) - FINE
  urlDispositivi_edit: "/dispositivi/modifica",
  urlDispositivi_verifydelete: "/dispositivi/verificauso/",
  urlDispositivi_delete: "/dispositivi/elimina/",
  urlDispositiviDpi_list: "/dispositivi/lista/dpi/d/",
  urlDispositiviDpi_edit: "/dispositivi/dpi/modifica",
  urlDispositiviUtenti_list: "/dispositivi/lista/utenti/d/",
  urlDispositiviUtenti_edit: "/dispositivi/utenti/modifica",
  urlDispositiviMezzi_list: "/dispositivi/lista/mezzi/d/",
  urlDispositiviMezzi_edit: "/dispositivi/mezzi/modifica",
  urlDispositiviAttrezzature_list: "/dispositivi/lista/attrezzature/d/",
  urlDispositiviAttrezzature_edit: "/dispositivi/attrezzature/modifica",
  urlDispositiviDispositivi_list: "/dispositivi/lista/dispositivi/d/",
  urlDispositiviDispositivi_edit: "/dispositivi/dispositivi/modifica",
  opIdDispositivi_add: "Device_add",
  opIdDispositivi_add_streamsets: "Device_add_streamsets",
  opIdDispositivi_edit: "Device_edit",
  opIdDispositivi_delete: "Device_delete",
  opIdDispositiviDpi_edit: "DevicePpes_edit",
  opIdDispositiviUtenti_edit: "DeviceUsers_edit",
  opIdDispositiviAttrezzature_edit: "DispositiviAttrezzature_edit",
  opIdDispositiviDispositivi_edit: "DispositiviDispositivi_edit",
  opIdDispositiviMezzi_edit: "DispositiviMezzi_edit",

  // --- TIPI DI MEZZO
  urlTipiMezzo_list: "/tipimezzo/lista",
  urlTipiMezzo_add: "/tipimezzo/aggiungi",
  urlTipiMezzo_edit: "/tipimezzo/modifica",
  urlTipiMezzo_delete: "/tipimezzo/elimina/",
  opIdTipiMezzo_add: "Tipimezzo_add",
  opIdTipiMezzo_edit: "Tipimezzo_edit",
  opIdTipiMezzo_delete: "Tipimezzo_delete",

  // --- MEZZI
  urlMezzi_list: "/mezzi/lista",
  urlMezzi_add: "/mezzi/aggiungi",
  urlMezzi_edit: "/mezzi/modifica",
  urlMezzi_delete: "/mezzi/elimina/",
  urlMezziZone_list: "/mezzi/lista/zone/u/",
  urlMezziZone_edit: "/mezzi/zone/modifica",
  opIdMezzi_add: "Mezzo_add",
  opIdMezzi_edit: "Mezzo_edit",
  opIdMezzi_delete: "Mezzo_delete",
  opIdMezziZone_edit: "MezziZone_edit",

  // --- OPERATIVITA' - DASHBOARD ---
  urlKpiLavoriZone: "/analisi/kpi/numerolavorieseguitiZone",
  urlKpiLavoriZone_ultimi: "/analisi/kpi/numerolavoriultimigiorniZone",
  urlKpiLavoriConAnomalieZone: "/analisi/kpi/numerolavoriconanomalieZone",
  urlKpiLavoriConAnomalieZone_ultimi: "/analisi/kpi/numerolavoriconanomalieultimigiorniZone",
  urlKpiOperatoriZone: "/analisi/kpi/numerooperatorilavoesegZone",
  urlKpiOperatoriZone_ultimi: "/analisi/kpi/numerolavoratoridistultimigiorniZone",
  urlKpiCheckQuotidianiZone: "/analisi/kpi/numerocheckquotidianiZone",
  urlKpiCheckQuotidianiZone_ultimi: "/analisi/kpi/numerocheckquotidianingiorniZone",
  // A. Stefanutti - POC OLIVETTI TIM REQ 12 - Revisione Dashboard (trello https://trello.com/c/TwHMMl5k)
  urlKpiAllarmiDPI: "/analisi/kpi/totaleallarmidpi",
  urlKpiAllarmiUAT: "/analisi/kpi/totaleallarmiuat",
  urlKpiCheckTotali: "/analisi/kpi/totalecheck",
  urlKpiCheckOneShot: "/analisi/kpi/totaleoneshotcheck",
  urlKpiCheckOneShotConAnomalie: "/analisi/kpi/totaleoneshotcheckconanomalie",
  urlKpiCheckActive: "/analisi/kpi/totaleactivecheck",
  urlKpiCheckActiveConAllarmi: "/analisi/kpi/totaleactivecheckconallarmi",
  urlKpiCheckTeam: "/analisi/kpi/totaleteamcheck",
  urlKpiCheckTeamConAnomalie: "/analisi/kpi/totaleteamcheckconanomalie",
  // A. Stefanutti - SF SNAM POC FASE II Rimozione reportistica Multi OP (trello https://trello.com/c/IMdiIl90)
  urlKpiCheckManDown: "/analisi/kpi/totalecheckmandown",
  urlKpiCheckManDownConAnomalie: "/analisi/kpi/totalecheckmandownconallarmiuat",
  // A. Stefanutti - SF SNAM POC FASE II Rimozione reportistica Multi OP (trello https://trello.com/c/IMdiIl90) - FINE
  // A. Stefanutti - POC OLIVETTI TIM REQ 12 - Revisione Dashboard (trello https://trello.com/c/TwHMMl5k) - FINE
  urlGraphLavoriMonoMultiZone: "/analisi/lavoriperoperatmultiopZone",
  urlGraphLavoriPerZonaZone: "/analisi/lavoriperzonaZone",
  urlGraphLavoriPerAttivitaZone: "/analisi/lavoriperattivitaZone",
  urlGraphLavoriPerTipoAssettoZone: "/analisi/lavoripertipoassettoZone",
  // A. Stefanutti - Nuovo grafico "Lavori per tipo" (trello https://trello.com/c/vaceckHl)
  urlGraphLavoriPerTipo: "/analisi/lavoripertipo",
  // A. Stefanutti - Nuovo grafico "Lavori per tipo" - FINE

  // DEMO_SAIPEM_REQ_04 - A. Stefanutti
  // Dashboard per situazione consegne DPI.
  // --- OPERATIVITA' - DASHBOARD CONSEGNA DPI ---
  urlKpiDpiAssegnatiZone: "/analisi/kpi/totaledpiassegnatiZone",
  urlKpiDpiAssegnatiZone_ultimi: "/analisi/kpi/numerolavoriultimigiorni", // TODO
  urlKpiDpiDaAssegnareZone: "/analisi/kpi/totaledpidaassegnareZone",
  urlKpiDpiDaAssegnareZone_ultimi: "/analisi/kpi/numerolavoratoridistultimigiorni", // TODO
  urlKpiDpiScadutiAssegnatiZone: "/analisi/kpi/totaledpiscadutiassegnatiZone",
  urlKpiDpiScadutiAssegnatiZone_ultimi: "/analisi/kpi/numerolavoriconanomalieultimigiorni", // TODO
  urlKpiDpiInScadenzaAssegnatiZone: "/analisi/kpi/totaledpiinscadenzaassegnatiZone",
  urlKpiDpiInScadenzaAssegnatiZone_ultimi: "/analisi/kpi/numerocheckquotidianingiorni", // TODO
  urlGraphDpiDaAssegnarePerTipoZone: "/analisi/dpidisponibilipertipoZone",
  // A. Stefanutti - Nuovo grafico DPI assegnati per tipo DPI (https://trello.com/c/WIbn2UsI)
  urlGraphDpiAssegnatiPerTipoZone: "/analisi/dpiassegnatipertipoZone",
  // A. Stefanutti - Nuovo grafico DPI assegnati per tipo DPI (https://trello.com/c/WIbn2UsI) - FINE
  // DEMO_SAIPEM_REQ_04 - A. Stefanutti - FINE
  // A. Stefanutti - Nuovi grafici DPI per OLITIM (https://trello.com/c/eVTCabA9)
  urlGraphDpiAssegnatiInScadenzaPerTipoZone: "/analisi/dpiassegnatiinscadenzapertipo",
  urlGraphDpiAssegnatiScadutiPerTipoZone: "/analisi/dpiassegnatiscadutipertipo",
  urlGraphDpiDaAssegnareInScadenzaPerTipoZone: "/analisi/dpigiacenzainscadenzapertipo",
  urlGraphDpiDaAssegnareScadutiPerTipoZone: "/analisi/dpigiacenzascadutipertipo",
  urlGraphDpiInScadenzaPerOperatoreZone: "/analisi/dpiscadenzaperoperatore",
  urlGraphDpiScadutiPerOperatoreZone: "/analisi/dpiscadutiperoperatore",
  // A. Stefanutti - Nuovi grafici DPI per OLITIM (https://trello.com/c/eVTCabA9) - FINE
  // A. Stefanutti - Integrazione Grafana (https://trello.com/c/k6feLqh9)
  urlGrafanaDashboardAnalyticsCompliance: "", // TODO
  // A. Stefanutti - Modifiche integrazione Grafana (https://trello.com/c/yJXu00pS)
  urlGrafanaDashboardAnalyticsDpi: "/d/olivetti/ppe?orgId="+process.env.REACT_APP_GRAFANA_ORG_ID+"&kiosk",
  // A. Stefanutti - Modifiche integrazione Grafana (https://trello.com/c/yJXu00pS) - FINE
  urlGrafanaDashboardAnalyticsFieldOperations: "", // TODO
  // A. Stefanutti - Integrazione Grafana (https://trello.com/c/k6feLqh9) - FINE

  // --- OPERATIVITA' - RISULTATI CONTROLLI ---
  urlControlli_filters: "/controlli/lista/filtri", // Dati per popolare il form di filtraggio dei controlli
  urlControlli_search: "/controlli/cerca", // Deve rimanere, in quanto usata per recuperare il dettaglio di un singolo controllo
  urlControlliZone_search: "/controlli/cercaZone",
  opIdControlliDpiCompletati: "Controlli_Dpi_Completati",
  urlGraphCQMotivazioniNonRilevatiZone: "/analisi/checkquotidianimotivazioniZone",
  urlGraphCQPerEsitoZone: "/analisi/checkquotidianiperesitoZone",
  urlGraphCQPerGiornoZone: "/analisi/checkquotidianipergiornoZone",
  urlGraphCQTagKOPerTipoDpiZone: "/analisi/checkquotidianitagnonrildotZone",
  urlGraphCMMotivazioniNonRilevatiZone: "/analisi/checkmultiopmotivazionitagnonrilZone",
  urlGraphCMTagKOPerTipoDpiZone: "/analisi/checkmultiopdpirichiestinonrildotZone",


  urlGraphCMPerGiornoZone: "/analisi/checkmultioppergiornoZone",

  
  // --- OPERATIVITA' - LAVORI ---
  urlGraphLavoriMonoPerAttivitaZone: "/analisi/lavorisingopperattivitaZone",
  urlGraphLavoriMonoPerTipoAssettoZone: "/analisi/lavorisingoppertipoassettoZone",
  urlGraphLavoriMonoConSenzaAllarmiZone: "/analisi/lavorisingopconsenzaallarmiZone",
  urlGraphLavoriMonoAllarmiSmsPerZonaZone: "/analisi/allarmismssingopperzonaZone",
  urlGraphLavoriMonoAllarmiPerTipoDpiESoluzioneZone: "/analisi/allarmisingoprientratipersoluzioneZone",
  // A. Stefanutti - Nuovo grafico "Lavori per tipo" (trello https://trello.com/c/vaceckHl)
  urlGraphLavoriMonoPerTipo: "/analisi/lavorisingoppertipo",
  // A. Stefanutti - Nuovo grafico "Lavori per tipo" - FINE
  // A. Stefanutti - Nuovo grafico "Allarmi per tipo" (trello https://trello.com/c/qtQE2AFV)
  urlGraphLavoriMonoAllarmiPerTipo: "/analisi/allarmipertipo",
  // A. Stefanutti - Nuovo grafico "Allarmi per tipo" (trello https://trello.com/c/qtQE2AFV) - FINE
  urlGraphLavoriMultiPerAttivitaZone: "/analisi/lavorimultiopperattivitaZone",
  urlGraphLavoriMultiPerTipoAssettoZone: "/analisi/lavorimultioppertipoassettoZone",
  urlGraphLavoriMultiAllarmiSmsPerZonaZone: "/analisi/allarmismsmultiopperzonaZone", // TODO
  urlGraphLavoriMultiPerNumeroOperatoriZone: "/analisi/lavorimultiopsquadreZone",
  // A. Stefanutti - Nuovo grafico "Lavori per tipo" (trello https://trello.com/c/vaceckHl)
  urlGraphLavoriMultiPerTipo: "/analisi/lavorimultioppertipo",
  // A. Stefanutti - Nuovo grafico "Lavori per tipo" - FINE

  // OPERATIVITA' - REPORTS ANALITICI
  urlLavoriEseguiti_filters: "/lavorieseguiti/lista/filtri", // Dati per popolare il form di filtraggio degli alert
  urlLavoriEseguitiZone_search: "/lavorieseguiti/cercaZone",
  urlAllarmi_filters: "/allarmi/lista/filtri", // Dati per popolare il form di filtraggio degli alert
  urlAllarmiZone_search: "/allarmi/cercaZone",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47)
  urlAllarmiUAT_filters: "/allarmi/uat/lista/filtri", // Dati per popolare il form di filtraggio degli alert UAT
  urlAllarmiUATZone_search: "/allarmi/uat/cercaZone",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47) - FINE
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti
  // Verbale consegna DPI.
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni (trello https://trello.com/c/GX3i5GoX)
  urlVerbaliConsegna_filters: "/verbaliconsegna/lista/filtri", // Dati per popolare il form di filtraggio degli alert
  urlVerbaliConsegnaZone_search: "/verbaliconsegna/cercaZone",
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni - FINE
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti - FINE
  urlBatteriaZone_search: "/analisi/report/dpi/livellibatteriazone",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti
  // Report utenti che non hanno fatto il check quotidiano (senza interazione con schedulatore A2A).
  urlOperatoriSenzaCQ_search: "/utenti/cerca/senzacq",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti - FINE
  urlOperatoriDotazioneIncompletaZone_search: "/analisi/report/dotazionidpiincompletezone",

  // --- OPERATIVITA' - DPI ---
  urlReportDpi: "/analisi/report/dpiutente",
  urlTableSituazioneDpiZone: "/analisi/report/dpiutentezone",
  // A. Stefanutti - POC OLIVETTI TIM Req 4 Vista attrezzature non assegnate (con dispositivi) (trello https://trello.com/c/hw2r5lae)
  urlTableSituazioneDpiNonAssegnatiZone: "/analisi/report/dpinonassociati",
  // A. Stefanutti - POC OLIVETTI TIM Req 4 Vista attrezzature non assegnate (con dispositivi) (trello https://trello.com/c/hw2r5lae) - FINE

  // --- ATTIVITA ---
  urlAttivita_list: "/attivita/lista",
  urlAttivita_add: "/attivita/aggiungi",
  urlAttivita_edit: "/attivita/modifica",
  urlAttivita_delete: "/attivita/elimina/",
  urlAttivitaCompetenze_list: "/attivita/competenze/lista/u/",
  urlAttivitaCompetenze_edit: "/attivita/competenze/modifica",
  opIdAttivita_add: "Attivita_add",
  opIdAttivita_edit: "Attivita_edit",
  opIdAttivita_delete: "Attivita_delete",
  opIdAttivitaCompetenze_edit: "AttivitaCompetenze_edit",

  // --- LAVORI ---
  urlLavori_list: "/lavori/lista",
  urlLavori_add: "/lavori/aggiungi",
  urlLavori_edit: "/lavori/modifica",
  urlLavori_delete: "/lavori/elimina/",
  urlLavoriAttivita_list: "/lavori/attivita/lista/l/",
  urlLavoriAttivita_edit: "/lavori/attivita/modifica",
  urlLavoriAree_list: "/lavori/lista/aree/l/",
  urlLavoriAree_edit: "/lavori/aree/modifica",
  opIdLavori_add: "Job_add",
  opIdLavori_edit: "Job_edit",
  opIdLavori_delete: "Job_delete",
  opIdLavoriAttivita_edit: "JobAttivita_edit",
  
  // --- ALLEGATI ---
  urlAllegatiSessioni_download: "/allegati/sessioni/scarica",
  urlAllegatiCantieri_download: "/allegati/cantieri/scarica",
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti
  // Verbale consegna DPI.
  urlAllegatiVerbaliConsegna_download: "/allegati/verbaliconsegna/scarica",
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti - FINE
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_008, REQ_FUN_DPIATTR_009, REQ_FUN_DPIATTR_010
  // REQ_TEC_DPIATTR_WEB_009, REQ_TEC_DPIATTR_WEB_010, REQ_TEC_DPIATTR_WEB_011
  // Creare Amministrazione modelli.
  urlAllegatiModelli_download: "/allegati/modelli/scarica",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  // --- REALTIME
  urlSituazioneRT: "/rt/situazione",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47)
  urlAllarmiRT: "/allarmi/listaconultimostorico",
  urlDettagliAllarme: "/allarmi/constorici/",
  urlPresaInCaricoAllarmeUAT: "/allarmi/presaincarico/uat",
  opIdPresaInCaricoAllarmeUAT: "Alert_UAT_charge",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47) - FINE
  
  // --- TELEMETRIA
  urlTelemetryEntities_list: "/telemetria/dati/entita/lista",
  urlDeviceTelemetryData: "/telemetria/dati/dispositivo",
  urlAzioniAttrezzature: "/telemetria/attrezzature/azioni",
  urlDeviceTelemetryDataByInterval: "/telemetria/dati/tracking",

  // --- GEOFENCING ---
  urlGfcStop: "/sistema/gfc/0",
  urlGfcStart: "/sistema/gfc/1",
  urlGfcStatus: "/sistema/gfc/status",
  // A. Stefanutti - Evoluzione Geofencing: associazione con entità di alto livello (trello https://trello.com/c/ygpFrJO7)
  urlGfcConfigStatus: "/sistema/gfc/config/status",
  urlGfcConfigUpdate: "/sistema/gfc/config/aggiorna",
  opIdGfcConfigUpdate: "GFC_Config_add",
  // A. Stefanutti - Evoluzione Geofencing: associazione con entità di alto livello (trello https://trello.com/c/ygpFrJO7) - FINE

  // A. Stefanutti - Cancellazione dati obsoleti (trello https://trello.com/c/hqYb423c)
  // --- CANCELLAZIONE DATI OBSOLETI ---
  urlDataDeleteStop: "/sistema/dynamicsched/0",
  urlDataDeleteStart: "/sistema/dynamicsched/1",
  urlDataDeleteStatus: "/sistema/dynamicsched/status",
  // A. Stefanutti - Cancellazione dati obsoleti (trello https://trello.com/c/hqYb423c) - FINE

  // --- BLACK BOX ---
  urlBBFilterList: "/blackbox/lista/filtri", // Dati per popolare il form di filtraggio dei log
  urlBBSearch: "/blackbox/cerca",
  urlBBTimeline: "/blackbox/timeline",
  
  // --- LOG ---
  urlLogTimeline: "/log/timeline",

  // --- AREE
  urlAree_list: "/aree/lista",
  urlAree_add: "/aree/aggiungi",
  urlAree_edit: "/aree/modifica",
  urlAree_delete: "/aree/elimina/",
  urlAreeDispositivi_list: "/aree/lista/dispositivi/a/",
  urlAreeDispositivi_edit: "/aree/dispositivi/modifica",
  opIdAree_add: "Area_add",
  opIdAree_edit: "Area_edit",
  opIdAree_delete: "Area_delete",
  opIdAreeDispositivi_edit: "AreaDispositivi_edit",

  // --- SITI
  urlSiti_list: "/siti/lista",
  urlSiti_add: "/siti/aggiungi",
  urlSiti_edit: "/siti/modifica",
  urlSiti_delete: "/siti/elimina/",
  urlSitiDispositivi_list: "/siti/lista/dispositivi/d/",
  urlSitiDispositivi_edit: "/siti/dispositivi/modifica",
  urlSitiAllegati_list: "/siti/lista/allegati/d/",
  urlSitiAllegati_edit: "/siti/allegati/modifica",
  // urlSitiZone_list: "/siti/lista/zone/a/",
  // urlSitiZone_edit: "/siti/zone/modifica",
  opIdSiti_add: "Site_add",
  opIdSiti_edit: "Site_edit",
  opIdSiti_delete: "Site_delete",
  opIdSitiDispositivi_edit: "SitoDispositivi_edit",
  opIdSitiAllegati_edit: "SitiAllegati_edit",
  // opIdSitiZone_edit: "SitiZone_edit",

  // --- TIPI DI PARAMETRO DI CONFIGURAZIONE ---
  urlTipiParametroConfigurazione_list: "/tipiparametroconfig/lista",
  urlTipiParametroConfigurazione_add: "/tipiparametroconfig/aggiungi",
  urlTipiParametroConfigurazione_edit: "/tipiparametroconfig/modifica",
  urlTipiParametroConfigurazione_delete: "/tipiparametroconfig/elimina/",
  opIdTipiParametroConfigurazione_add: "TipiParametroConfig_add",
  opIdTipiParametroConfigurazione_edit: "TipiParametroConfig_edit",
  opIdTipiParametroConfigurazione_delete: "TipiParametroConfig_delete",

  // --- TIPI DI CANTIERE ---
  urlTipiCantiere_list: "/tipicantiere/lista",
  urlTipiCantiere_add: "/tipicantiere/aggiungi",
  urlTipiCantiere_edit: "/tipicantiere/modifica",
  urlTipiCantiere_delete: "/tipicantiere/elimina/",
  opIdTipiCantiere_add: "Tipicantiere_add",
  opIdTipiCantiere_edit: "Tipicantiere_edit",
  opIdTipiCantiere_delete: "Tipicantiere_delete",

  // --- CANTIERI ---
  urlCantieri_list: "/cantieri/lista",
  urlCantieri_recenti_list: "/cantieri/lista/recenti",
  urlCantieri_add: "/cantieri/aggiungi",
  urlCantieri_edit: "/cantieri/modifica",
  urlCantieri_delete: "/cantieri/elimina/",
  urlCantiereSegnaletica: "/cantieri/cartelli/lista/",
  urlCantiereStorici: "/cantieri/storico/",
  urlCantiereStoricoQuestionari: "/questionario/recupera",
  urlCantiereStoricoRavica: "/ravica/recupera",
  urlCantiereDettagli: "/cantieri/storico/singolo/",
  urlCantiereAllegati: "/allegati/cantiere/",
  opIdCantieri_add: "Cantieri_add",
  opIdCantieri_edit: "Cantieri_edit",
  opIdCantieri_delete: "Cantieri_delete",
  // --- ASSOCIAZIONE CANTIERI-LAVORI ---
  urlCantieriLavori_list: "/cantieri/lista/lavori/d/",
  urlCantieriLavori_edit: "/cantieri/lavori/modifica",
  opIdCantieriLavori_edit: "CantiereLavori_edit",
  // --- ASSOCIAZIONE CANTIERI-AREE ---
  urlCantieriAree_list: "/cantieri/lista/aree/d/",
  urlCantieriAree_edit: "/cantieri/aree/modifica",
  opIdCantieriAree_edit: "CantiereAree_edit",
  // --- ASSOCIAZIONE CANTIERI-DISPOSITIVI ---
  urlCantieriDispositivi_list: "/cantieri/lista/dispositivi/d/",
  urlCantieriDispositivi_edit: "/cantieri/dispositivi/modifica",
  opIdCantieriDispositivi_edit: "CantiereDispositivi_edit",

  // --- STATI CANTIERE ---
  urlStatiCantiere_list: "/staticantiere/lista",
  urlStatiCantiere_add: "/staticantiere/aggiungi",
  urlStatiCantiere_edit: "/staticantiere/modifica",
  urlStatiCantiere_delete: "/staticantiere/elimina/",
  opIdStatiCantiere_add: "Staticantiere_add",
  opIdStatiCantiere_edit: "Staticantiere_edit",
  opIdStatiCantiere_delete: "Staticantiere_delete",

  // --- SOTTOSTATI CANTIERE ---
  urlSottostatiCantiere_list: "/sottostaticantiere/lista",
  urlSottostatiCantiere_add: "/sottostaticantiere/aggiungi",
  urlSottostatiCantiere_edit: "/sottostaticantiere/modifica",
  urlSottostatiCantiere_delete: "/sottostaticantiere/elimina/",
  opIdSottostatiCantiere_add: "SottoStaticantiere_add",
  opIdSottostatiCantiere_edit: "SottoStaticantiere_edit",
  opIdSottostatiCantiere_delete: "SottoStaticantiere_delete",

  // --- STATI UTENTE ---
  urlStatiUtente_list: "/statiutente/lista",
  urlStatiUtente_add: "/statiutente/aggiungi",
  urlStatiUtente_edit: "/statiutente/modifica",
  urlStatiUtente_delete: "/statiutente/elimina/",
  opIdStatiUtente_add: "StatoUtente_add",
  opIdStatiUtente_edit: "StatoUtente_edit",
  opIdStatiUtente_delete: "StatoUtente_delete",

  // --- STATI MEZZO ---
  urlStatiMezzo_list: "/statimezzo/lista",
  urlStatiMezzo_add: "/statimezzo/aggiungi",
  urlStatiMezzo_edit: "/statimezzo/modifica",
  urlStatiMezzo_delete: "/statimezzo/elimina/",
  opIdStatiMezzo_add: "StatoMezzo_add",
  opIdStatiMezzo_edit: "StatoMezzo_edit",
  opIdStatiMezzo_delete: "StatoMezzo_delete",

    // --- STATI ATTREZZATURA ---
  urlStatiAttrezzatura_list: "/statiattrezzatura/lista",
  urlStatiAttrezzatura_add: "/statiattrezzatura/aggiungi",
  urlStatiAttrezzatura_edit: "/statiattrezzatura/modifica",
  urlStatiAttrezzatura_delete: "/statiattrezzatura/elimina/",
  opIdStatiAttrezzatura_add: "Statiattrezzatura_add",
  opIdStatiAttrezzatura_edit: "Statiattrezzatura_edit",
  opIdStatiAttrezzatura_delete: "Statiattrezzatura_delete",

  // --- TIPI DI ACQUISIZIONE ---
  urlTipiAcquisizione_list: "/tipiacquisizione/lista",
  urlTipiAcquisizione_add: "/tipiacquisizione/aggiungi",
  urlTipiAcquisizione_edit: "/tipiacquisizione/modifica",
  urlTipiAcquisizione_delete: "/tipiacquisizione/elimina/",
  opIdTipiAcquisizione_add: "TipiAcquisizione_add",
  opIdTipiAcquisizione_edit: "TipiAcquisizione_edit",
  opIdTipiAcquisizione_delete: "TipiAcquisizione_delete",

  // --- TIPI DI RILEVAMENTO PRESENZA ---
  urlTipiRilevamentoPresenza_list: "/tipirilevamento/lista",
  urlTipiRilevamentoPresenza_add: "/tipirilevamento/aggiungi",
  urlTipiRilevamentoPresenza_edit: "/tipirilevamento/modifica",
  urlTipiRilevamentoPresenza_delete: "/tipirilevamento/elimina/",
  opIdTipiRilevamentoPresenza_add: "TipiRilevamentoPresenza_add",
  opIdTipiRilevamentoPresenza_edit: "TipiRilevamentoPresenza_edit",
  opIdTipiRilevamentoPresenza_delete: "TipiRilevamentoPresenza_delete",

  // --- TIPI DI CONTROLLO ---
  urlTipiControllo_list: "/tipicontrollo/lista",
  urlTipiControllo_add: "/tipicontrollo/aggiungi",
  urlTipiControllo_edit: "/tipicontrollo/modifica",
  urlTipiControllo_delete: "/tipicontrollo/elimina/",
  opIdTipiControllo_add: "TipiControllo_add",
  opIdTipiControllo_edit: "TipiControllo_edit",
  opIdTipiControllo_delete: "TipiControllo_delete",
  
  // --- ESITI CONTROLLO ---
  urlEsitiControllo_list: "/esiticontrollo/lista",
  urlEsitiControllo_add: "/esiticontrollo/aggiungi",
  urlEsitiControllo_edit: "/esiticontrollo/modifica",
  urlEsitiControllo_delete: "/esiticontrollo/elimina/",
  opIdEsitiControllo_add: "EsitoControllo_add",
  opIdEsitiControllo_edit: "EsitoControllo_edit",
  opIdEsitiControllo_delete: "EsitoControllo_delete",

  // --- QUESTIONARI - GRUPPI DOMANDE ---
  urlGruppiDomande_list: "/gruppidomande/lista",
  urlGruppiDomande_add: "/gruppidomande/aggiungi",
  urlGruppiDomande_edit: "/gruppidomande/modifica",
  urlGruppiDomande_delete: "/gruppidomande/elimina/",
  urlGruppiDomandeDomande_list: "/gruppidomande/lista/domande/u/",
  urlGruppiDomandeDomande_edit: "/gruppidomande/domande/modifica",
  opIdGruppiDomande_add: "GruppiDomande_add",
  opIdGruppiDomande_edit: "GruppiDomande_edit",
  opIdGruppiDomande_delete: "GruppiDomande_delete",
  opIdGruppiDomandeDomande_edit: "GruppidomandeDomande_edit",

  // --- QUESTIONARI - DOMANDE ---
  urlDomande_list: "/domande/lista",
  urlDomande_add: "/domande/aggiungi",
  urlDomande_edit: "/domande/modifica",
  urlDomande_delete: "/domande/elimina/",
  urlDomandeRisposte_list: "/domande/lista/risposte/u/",
  urlDomandeRisposte_edit: "/domande/risposte/modifica",
  opIdDomande_add: "Domande_add",
  opIdDomande_edit: "Domande_edit",
  opIdDomande_delete: "Domande_delete",
  opIdDomandeRisposte_edit: "DomandeRisposte_edit",

  // --- QUESTIONARI - RISPOSTE ---
  urlRisposte_list: "/risposte/lista",
  urlRisposte_add: "/risposte/aggiungi",
  urlRisposte_edit: "/risposte/modifica",
  urlRisposte_delete: "/risposte/elimina/",
  opIdRisposte_add: "Risposte_add",
  opIdRisposte_edit: "Risposte_edit",
  opIdRisposte_delete: "Risposte_delete",

  // --- QUESTIONARI - TIPI QUESTIONARIO ---
  urlTipiQuestionario_list: "/tipiquestionario/lista",
  urlTipiQuestionario_add: "/tipiquestionario/aggiungi",
  urlTipiQuestionario_edit: "/tipiquestionario/modifica",
  urlTipiQuestionario_delete: "/tipiquestionario/elimina/",
  urlTipiQuestionarioGruppiDomande_list: "/tipiquestionario/lista/gruppodomande/u/",
  urlTipiQuestionarioGruppiDomande_edit: "/tipiquestionario/gruppidomande/modifica",
  opIdTipiQuestionario_add: "TipiQuestionario_add",
  opIdTipiQuestionario_edit: "TipiQuestionario_edit",
  opIdTipiQuestionario_delete: "TipiQuestionario_delete",
  opIdTipiQuestionarioGruppiDomande_edit: "TipiquestGruppidomande_edit",

  // --- QUESTIONARI - VERSIONI QUESTIONARIO ---
  urlVersioniQuestionario_list: "/versioniquestionario/lista",
  urlVersioniQuestionario_add: "/versioniquestionario/aggiungi",
  urlVersioniQuestionario_edit: "/versioniquestionario/modifica",
  urlVersioniQuestionario_delete: "/versioniquestionario/elimina/",
  opIdVersioniQuestionario_add: "VersioniQuestionario_add",
  opIdVersioniQuestionario_edit: "VersioniQuestionario_edit",
  opIdVersioniQuestionario_delete: "VersioniQuestionario_delete",

  // --- REGISTRO ASSEGNAZIONI ---
  urlRegistroAssegnazioniUtentiDpi_filters: "/registroasseg/lista/filtri",
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni (trello https://trello.com/c/GX3i5GoX)
  urlRegistroAssegnazioniUtentiDpiZone_search: "/registroasseg/cercaZone/utenti/dpi",
  // A. Stefanutti - Filtro zone e form ricerca in Verbali consegna e storico assegnazioni - FINE
  // A. Stefanutti - Errore storico assegnazioni da scheda utente (trello https://trello.com/c/IYeNFPg9)
  urlAssegnazioniPerUtente: "/registroasseg/cerca/perutente",
  // A. Stefanutti - Errore storico assegnazioni da scheda utente (trello https://trello.com/c/IYeNFPg9) - FINE


  // --- LIVELLI DI GRAVITA'
  urlLivelliGravita_list: "/livelligravita/lista",

  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk)
  // --- LIVELLI ESCALATION ---
  urlLivelliEscalation_list: "/livelliescalation/lista",
  urlLivelliEscalation_add: "/livelliescalation/aggiungi",
  urlLivelliEscalation_edit: "/livelliescalation/modifica",
  urlLivelliEscalation_delete: "/livelliescalation/elimina/",
  urlLivelliEscalationProfili_list: "/livelliescalation/profili/lista/u/",
  urlLivelliEscalationProfili_edit: "/livelliescalation/profili/modifica",
  opIdLivelliEscalation_add: "LivelliEscalation_add",
  opIdLivelliEscalation_edit: "LivelliEscalation_edit",
  opIdLivelliEscalation_delete: "LivelliEscalation_delete",
  opIdLivelliEscalationProfili_edit: "LivelliEscalationProfili_edit",
  
  // --- REFERENTI DI ZONA ---
  urlReferentiPossibiliPerZona_list: "/zone/possibilireferenti/lista/z/",
  urlZoneReferenti_list: "/zone/referenti/lista/z/",
  urlZoneReferenti_edit: "/zone/referenti/modifica",
  opIdZoneReferenti_edit: "ZoneReferenti_edit",

  // --- TURNI DI LAVORO ---
  urlTurniLavoro_list: "/turnilavoro/lista",
  urlTurniLavoro_add: "/turnilavoro/aggiungi",
  urlTurniLavoro_edit: "/turnilavoro/modifica",
  urlTurniLavoro_delete: "/turnilavoro/elimina/",
  opIdTurniLavoro_add: "TurniLavoro_add",
  opIdTurniLavoro_edit: "TurniLavoro_edit",
  opIdTurniLavoro_delete: "TurniLavoro_delete",

  urlAssegnazioneTurniLavoro_quadromensile: "/turnilavoro/quadromensile",
  urlAssegnazioneTurniLavoro_delete: "/turnilavoro/eliminaassegnazione/",
  opIdAssegnazioneTurniLavoro_delete: "AssegnazioniTurniLavoro_delete",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk) - FINE



  // ******************************************************************
  // Altri valori
  // ******************************************************************

  CODICESORGENTELOG_WEB: "WEB",
  CODICESORGENTELOG_MOBILE_DM: "DMMOBILE",
  CODICESORGENTELOG_MOBILE_MM: "MMMOBILE",
  CODICESORGENTELOG_REST: "REST",
  CODICESORGENTELOG_EDGE_SAW: "SAWEDGE",
  CODICESORGENTELOG_MOBILE_SAW: "SAWMOBILE",
  CODICESORGENTELOG_MOBILE_UAT: "UATMOBILE",
  
  OP_KIND_CODE_INIZIOLAVORO: "INIZIOLAVORO",
  OP_KIND_CODE_FINELAVORO: "FINELAVORO",
  // A. Stefanutti - Cambio attività a caldo (trello https://trello.com/c/1JwRbc2R)
  OP_KIND_CODE_CAMBIOATTIVITA: "CHGTASK",
  // A. Stefanutti - Cambio attività a caldo - FINE
  OP_KIND_CODE_QUEST: "QUEST",
  OP_KIND_CODE_RAVICA: "RAVI",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47)
  OP_KIND_CODE_ALLARMECREAZIONE: "ALLARMECREAZIONE",
  OP_KIND_CODE_ALLARMEPRESAINCARICO: "ALLARMEPRESAINCARICO",
  OP_KIND_CODE_ALLARMEAGGIORNAMENTO: "ALLARMEAGGIORNAMENTO",
  OP_KIND_CODE_ALLARMESOLUZIONE: "ALLARMESOLUZIONE",
  OP_KIND_CODE_ALLARMESOLUZIONEOP: "ALLARMESOLUZIONEOP",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47) - FINE
  
  IMPORT_FILE_KIND_JSON: 1,
  IMPORT_FILE_KIND_CSV: 2,
  IMPORT_FILE_KIND_DAT: 3,
  
  CSV_DEFAULT_SEPARATOR: ";",

  PERIOD_TUTTI: "tutti",
  PERIOD_ULTIMOMESE: "ultimomese",
  PERIOD_ULTIMASETTIMANA: "ultimasett",
  PERIOD_OGGI: "oggi",
  PERIOD_CUSTOM: "custom",

  REALTIME_MAP_ZOOM_LEVEL_DETAIL: 18,

  YARD_STATUS_CODE_INIZ: "INIZ",
  YARD_STATUS_CODE_APER: "APER",
  YARD_STATUS_CODE_SOSP: "SOSP",
  YARD_STATUS_CODE_SOSF: "SOSF",
  YARD_STATUS_CODE_CHIU: "CHIU",
  YARD_STATUS_CODE_TERM: "TERM",

  YARD_SUBSTATUS_CODE_INIZ: "INIZ",
  YARD_SUBSTATUS_CODE_ACON: "ACON",
  YARD_SUBSTATUS_CODE_CONS: "CONS",
  YARD_SUBSTATUS_CODE_ARES: "ARES",
  YARD_SUBSTATUS_CODE_REST: "REST",
  YARD_SUBSTATUS_CODE_TERM: "TERM",

  YARD_TYPE_CODE_SIC: "SIC",
  YARD_TYPE_CODE_NSIC: "NSIC",

  WARNING_OUTCOME_CODE_OK: "ES001",
  WARNING_OUTCOME_CODE_NOK: "ES002",
  WARNING_OUTCOME_CODE_POK: "ES003",
  WARNING_OUTCOME_CODE_ERR: "ERR",

  NOTIFICATIONS_REFRESH_INTERVAL: 15,
  NOTIFICATIONS_LEVEL_CODE_DEBUG: "DEBUG",
  NOTIFICATIONS_LEVEL_CODE_ERROR: "ERROR",
  NOTIFICATIONS_LEVEL_CODE_FATAL: "FATAL",
  NOTIFICATIONS_LEVEL_CODE_INFO: "INFO",
  NOTIFICATIONS_LEVEL_CODE_TRACE: "TRACE",
  NOTIFICATIONS_LEVEL_CODE_WARNING: "WARN",
  NOTIFICATIONS_CHANNEL_CODE_WEB: "WEB",
  NOTIFICATIONS_CHANNEL_CODE_EMAIL: "EMAIL",
  NOTIFICATIONS_CHANNEL_CODE_SMS: "SMS",

  MODULE_CODE_SYS: "SYS",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  MODULE_CODE_BB: "BB",
  MODULE_CODE_COS: "COS",
  MODULE_CODE_COSRT: "COSRT",
  MODULE_CODE_LDC: "LDC",
  MODULE_CODE_MM: "MM",
  MODULE_CODE_SAW: "SAW",
  MODULE_CODE_SAWRT: "SAWRT",
  MODULE_CODE_SSIGN: "SSIGN",
  MODULE_CODE_SSIGNRT: "SSIGNRT",
  MODULE_CODE_UAT: "UAT",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE
  // A. Stefanutti - Visualizzare nella landing page i moduli attualmente attivi nel sistema. (trello https://trello.com/c/zzscJvRg)
  MODULE_CODE_FATIG: "FATIG",
  MODULE_CODE_LFPPE: "LFPPE",
  MODULE_CODE_STRACK: "STRACK",
  // A. Stefanutti - Visualizzare nella landing page i moduli attualmente attivi nel sistema. (trello https://trello.com/c/zzscJvRg) - FINE

  PICTURE_COMPRESSION_MAXSIZE: 1, // the max size in MB, defaults to 2MB
  PICTURE_COMPRESSION_QUALITY: 1, // the quality of the image, max is 1
  PICTURE_COMPRESSION_MAXWIDTH: 200, // the max width of the output image, defaults to 1920px
  PICTURE_COMPRESSION_MAXHEIGHT: 240, // the max height of the output image, defaults to 1920px

  TELEMETRY_OBSOLESCENCE_CHECK_INTERVAL: 1, // minuti, intervallo di controllo dell'obsolescenza dei dati di telemetria di ogni dispositivo
  TELEMETRY_OBSOLESCENCE: 1, // minuti, tempo dopo il quale i dati di telemetria di un dispositivo sono dichiarati obsoleti
  TELEMETRYKIND_CODE_GEO: "GEO",
  TELEMETRYKIND_CODE_BATT: "BATT",
  TELEMETRYKIND_CODE_CHIPTEMP: "CHIPTEMP",
  TELEMETRYKIND_CODE_SSTELEM: "SSTELEM",
  SSIGN_DEVICE_NIBBLE_STOP: "0000", // cartello fermo
  SSIGN_DEVICE_NIBBLE_START: "0001", // cartello in partenza
  SSIGN_DEVICE_NIBBLE_FORCE_GPS_ON: "0100", // richiesta al GPS di aggiornare le coordinate 
  SSIGN_DEVICE_NIBBLE_CALIBRATE_IMU: "0101", // richiesta calibrazione IMU (non era presente nella GUI) 
  SSIGN_DEVICE_NIBBLE_AQUIRING: "0010", // cartello in acquisizione
  SSIGN_DEVICE_NIBBLE_STATUS: "0011", // è stato richiesto uno status  (attualmente unused)
  SSIGN_DEVICE_NIBBLE_FORCE_GPS_ON_DISABLE: "0110", // richiesta al GPS di aggiornare le coordinate 
  SSIGN_COORDS_SOURCE_REAL: "GPSC", // Reali
  SSIGN_COORDS_SOURCE_GW_REAL: "GPSG", // Gateway reali
  SSIGN_COORDS_SOURCE_GW_INITIAL: "GPSIG", // Gateway iniziali
  SSIGN_COORDS_SOURCE_SITE: "GPSS", // Sito/Cantiere

  FEATURE_MOBILE_MM: "MOBILE_MM",

  FEATURE_WEB_OPER_MENU: "WEB_OPER_MENU",
  FEATURE_WEB_OPER_DASH: "WEB_OPER_DASH",
  FEATURE_WEB_OPER_RT_MENU: "WEB_OPER_RT",
  FEATURE_WEB_OPER_RT_EVENTS: "WEB_OPER_RT_EVENTS",
  FEATURE_WEB_OPER_RT_EVENTS_JOBS: "WEB_OPER_RT_EVENTS_JOBS",
  FEATURE_WEB_OPER_RT_EVENTS_ALERTS: "WEB_OPER_RT_EVENTS_ALERTS",
  FEATURE_WEB_OPER_RT_EVENTS_YARDS: "WEB_OPER_RT_EVENTS_YARDS",
  FEATURE_WEB_OPER_RT_EVENTS_SIGNS: "WEB_OPER_RT_EVENTS_SIGNS",
  FEATURE_WEB_OPER_UTENTI: "WEB_OPER_UTENTI",
  FEATURE_WEB_OPER_UTENTI_SAW: "WEB_OPER_UTENTI_SAW",
  FEATURE_WEB_OPER_UTENTI_COS: "WEB_OPER_UTENTI_COS",
  FEATURE_WEB_OPER_UTENTI_MM: "WEB_OPER_UTENTI_MM",
  FEATURE_WEB_OPER_RT_TELEMETRY: "WEB_OPER_RT_TELEMETRY",
  FEATURE_WEB_OPER_RT_TRACKING: "WEB_OPER_RT_TRACKING",
  FEATURE_WEB_OPER_ANALYTICS_MENU: "WEB_OPER_ANALYTICS_MENU",
  FEATURE_WEB_OPER_ANALYTICS_DPI: "WEB_OPER_ANALYTICS_DPI",
  FEATURE_WEB_OPER_ANALYTICS_FIELD_OPS: "WEB_OPER_ANALYTICS_FIELD_OPS",
  FEATURE_WEB_OPER_ANALYTICS_COMPLIANCE: "WEB_OPER_ANALYTICS_COMPLIANCE",
  // A. Stefanutti - Integrazione Grafana (https://trello.com/c/k6feLqh9)
  FEATURE_WEB_OPER_ANALYTICS_COMPLIANCEGRAFANA: "WEB_OPER_ANALYTICS_COMPLIANCEGRAFANA",
  FEATURE_WEB_OPER_ANALYTICS_DPIGRAFANA: "WEB_OPER_ANALYTICS_DPIGRAFANA",
  FEATURE_WEB_OPER_ANALYTICS_FIELD_OPSGRAFANA: "WEB_OPER_ANALYTICS_FIELD_OPSGRAFANA",
  // A. Stefanutti - Integrazione Grafana (https://trello.com/c/k6feLqh9) - FINE
  FEATURE_WEB_OPER_REPORTS_MENU: "WEB_OPER_REPORTS_MENU",
  FEATURE_WEB_OPER_REPORTS_DPI_MENU: "WEB_OPER_REPORTS_DPI_MENU",
  FEATURE_WEB_OPER_REPORTS_FIELD_OPS_MENU: "WEB_OPER_REPORTS_FIELD_OPS_MENU",
  FEATURE_WEB_OPER_REPORTS_COMPLIANCE_MENU: "WEB_OPER_REPORTS_COMPLIANCE_MENU",
  FEATURE_WEB_OPER_REPORTS_SMARTTHINGS_MENU: "WEB_OPER_REPORTS_SMARTTHINGS_MENU",
  FEATURE_WEB_OPER_REPORTS_ALLARMI: "WEB_OPER_REPORTS_ALLARMI",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47)
  FEATURE_WEB_OPER_REPORTS_ALLARMI_UAT: "WEB_OPER_REPORTS_ALLARMI_UAT",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47) - FINE
  FEATURE_WEB_OPER_REPORTS_LAVORI_MONOOP: "WEB_OPER_REPORTS_LAVORI_MONOOP",
  FEATURE_WEB_OPER_REPORTS_LAVORI_MULTIOP: "WEB_OPER_REPORTS_LAVORI_MULTIOP",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti
  // Report utenti che non hanno fatto il check quotidiano (senza interazione con schedulatore A2A).
  FEATURE_WEB_OPER_REPORTS_OPERATORI_SENZACQ: "WEB_OPER_REPORTS_OPERATORI_SENZACQ",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti - FINE
  FEATURE_WEB_OPER_REPORTS_OPERATORI_DOTAZ_INCOMPL: "WEB_OPER_REPORTS_OPERATORI_DOTAZ_INCOMPL",
  FEATURE_WEB_OPER_REPORTS_BATTERIA: "WEB_OPER_REPORTS_BATTERIA",
  FEATURE_WEB_OPER_REPORTS_CHECK_QUOT: "WEB_OPER_REPORTS_CHECK_QUOT",
  FEATURE_WEB_OPER_REPORTS_CHECK_MULTI: "WEB_OPER_REPORTS_CHECK_MULTI",
  FEATURE_WEB_OPER_DPI: "WEB_OPER_DPI",
  FEATURE_WEB_OPER_DPI_VERBALI: "WEB_OPER_DPI_VERBALI",
  FEATURE_WEB_OPER_DPI_MATRICE_IDONTIPIDPI: "WEB_OPER_DPI_MATRICE_IDONTIPIDPI",
  // A. Stefanutti - Matrice Idoneità - Tipi di DPI - FINE
  // A. Stefanutti - Matrice Operatori-DPI (https://trello.com/c/C2vW3zNf)
  FEATURE_WEB_OPER_DPI_MATRICE_OPERDPI: "WEB_OPER_DPI_MATRICE_OPERDPI",
  // A. Stefanutti - Matrice Operatori-DPI (https://trello.com/c/C2vW3zNf) - FINE
  // DEMO_SAIPEM_REQ_01 - A. Stefanutti - FINE
  FEATURE_WEB_OPER_DPI_STORICO_ASSEGNAZIONI: "WEB_OPER_DPI_STORICO_ASSEGNAZIONI",
  FEATURE_WEB_OPER_BB_MENU: "WEB_VIEW_LOG_MENU",
  FEATURE_WEB_OPER_BB_REG: "WEB_VIEW_LOG_REG",
  
  // EVOLUTIVE SMART ASSET P1 - A. Stefanutti
  // Vista interventi
  FEATURE_WEB_OPER_ORDINI_MENU: "WEB_OPER_ORDINI_MENU",
  FEATURE_WEB_OPER_INTERVENTI: "WEB_OPER_INTERVENTI",
  // EVOLUTIVE SMART ASSET P1 - A. Stefanutti - FINE
  FEATURE_WEB_OPER_ORDINI: "WEB_OPER_ORDINI",
  FEATURE_WEB_OPER_DASH_ORDINI: "WEB_OPER_DASH_ORDINI",
  FEATURE_WEB_OPER_CLIENTI: "WEB_OPER_CLIENTI",
  FEATURE_WEB_OPER_SCHEDACLIENTE: "WEB_OPER_SCHEDACLIENTE",
  FEATURE_AZIONE_ORDINI_ASSEGNA: "AZIONE_ORDINI_ASSEGNA",
  FEATURE_AZIONE_ORDINI_CAMBIAASSEGNAZIONE: "AZIONE_ORDINI_CAMBIAASSEGNAZIONE",
  FEATURE_AZIONE_ORDINI_ACCETTA: "AZIONE_ORDINI_ACCETTA",
  FEATURE_AZIONE_ORDINI_RIFIUTA: "AZIONE_ORDINI_RIFIUTA",
  FEATURE_AZIONE_ORDINI_CANCELLA: "AZIONE_ORDINI_CANCELLA",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47)
  FEATURE_AZIONE_ALLARMI_PRESAINCARICO: "AZIONE_ALLARMI_PRESAINCARICO",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47) - FINE
  FEATURE_WEB_OPER_GFC: "WEB_OPER_GFC",
  FEATURE_AZIONE_GFC_START: "AZIONE_GFC_START",
  FEATURE_AZIONE_GFC_STOP: "AZIONE_GFC_STOP",
  FEATURE_AZIONE_GFC_RESTART: "AZIONE_GFC_RESTART",

  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk)
  FEATURE_WEB_OPER_REFERENTI_E_ESCALATION: "WEB_OPER_REFERENTI_E_ESCALATION",
  FEATURE_WEB_OPER_LIVELLI_ESCALATION: "WEB_OPER_LIVELLI_ESCALATION",
  FEATURE_WEB_ADMIN_LIVESCALAT_GEST: "WEB_ADMIN_LIVESCALAT_GEST",
  FEATURE_WEB_ADMIN_LIVESCALAT_GEST_ADD: "WEB_ADMIN_LIVESCALAT_GEST_ADD",
  FEATURE_WEB_ADMIN_LIVESCALAT_GEST_EDIT: "WEB_ADMIN_LIVESCALAT_GEST_EDIT",
  FEATURE_WEB_ADMIN_LIVESCALAT_GEST_DELETE: "WEB_ADMIN_LIVESCALAT_GEST_DELETE",
  FEATURE_WEB_OPER_REFERENTI_ZONE: "WEB_OPER_REFERENTI_ZONE",
  FEATURE_WEB_ADMIN_TURNILAVORO_GEST: "WEB_ADMIN_TURNILAVORO_GEST",
  FEATURE_WEB_ADMIN_TURNILAVORO_GEST_ADD: "WEB_ADMIN_TURNILAVORO_GEST_ADD",
  FEATURE_WEB_ADMIN_TURNILAVORO_GEST_EDIT: "WEB_ADMIN_TURNILAVORO_GEST_EDIT",
  FEATURE_WEB_ADMIN_TURNILAVORO_GEST_DELETE: "WEB_ADMIN_TURNILAVORO_GEST_DELETE",
  FEATURE_WEB_OPER_TURNI_LAVORO: "WEB_OPER_TURNI_LAVORO",
  FEATURE_WEB_ADMIN_UTENTI_GEST_ADDTURNI: "WEB_ADMIN_UTENTI_GEST_ADDTURNI",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.7 Gestione turni responsabili con priorità (https://trello.com/c/AiDREXvk) - FINE

  FEATURE_WEB_ADMIN_MENU: "WEB_ADMIN_MENU",
  FEATURE_WEB_ADMIN_SIST_MENU: "WEB_ADMIN_SIST_MENU",
  FEATURE_WEB_ADMIN_ATTREZZ_STATI_GEST: "WEB_ADMIN_ATTREZZ_STATI_GEST",

  FEATURE_WEB_ADMIN_FUNZ_GEST: "WEB_ADMIN_FUNZ_GEST",
  FEATURE_WEB_ADMIN_FUNZ_GEST_ADD: "WEB_ADMIN_FUNZ_GEST_ADD",
  FEATURE_WEB_ADMIN_FUNZ_GEST_EDIT: "WEB_ADMIN_FUNZ_GEST_EDIT",
  FEATURE_WEB_ADMIN_FUNZ_GEST_DELETE: "WEB_ADMIN_FUNZ_GEST_DELETE",

  FEATURE_WEB_ADMIN_LOGLEV_GEST: "WEB_ADMIN_LOGLEV_GEST",
  FEATURE_WEB_ADMIN_MODULI_GEST: "WEB_ADMIN_MODULI_GEST",
  FEATURE_WEB_ADMIN_MODULI_GEST_ADD: "WEB_ADMIN_MODULI_GEST_ADD",
  FEATURE_WEB_ADMIN_MODULI_GEST_EDIT: "WEB_ADMIN_MODULI_GEST_EDIT",
  FEATURE_WEB_ADMIN_MODULI_GEST_DELETE: "WEB_ADMIN_MODULI_GEST_DELETE",

  FEATURE_WEB_ADMIN_NOT_MENU: "WEB_ADMIN_NOT_MENU",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST: "WEB_ADMIN_NOT_CANALI_GEST",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST_ADD: "WEB_ADMIN_NOT_CANALI_GEST_ADD",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST_EDIT: "WEB_ADMIN_NOT_CANALI_GEST_EDIT",
  FEATURE_WEB_ADMIN_NOT_CANALI_GEST_DELETE: "WEB_ADMIN_NOT_CANALI_GEST_DELETE",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST: "WEB_ADMIN_NOT_LIVELLI_GEST",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST_ADD: "WEB_ADMIN_NOT_LIVELLI_GEST_ADD",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST_EDIT: "WEB_ADMIN_NOT_LIVELLI_GEST_EDIT",
  FEATURE_WEB_ADMIN_NOT_LIVELLI_GEST_DELETE: "WEB_ADMIN_NOT_LIVELLI_GEST_DELETE",

  FEATURE_WEB_ADMIN_COMP_GEST: "WEB_ADMIN_COMP_GEST",
  FEATURE_WEB_ADMIN_COMP_GEST_ADD: "WEB_ADMIN_COMP_GEST_ADD",
  FEATURE_WEB_ADMIN_COMP_GEST_EDIT: "WEB_ADMIN_COMP_GEST_EDIT",
  FEATURE_WEB_ADMIN_COMP_GEST_DELETE: "WEB_ADMIN_COMP_GEST_DELETE",

  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti
  // Differenziazione dell'assetto di riferimento di ogni utente in funzione della sua mansione.
  FEATURE_WEB_ADMIN_IDON_GEST: "WEB_ADMIN_IDON_GEST",
  FEATURE_WEB_ADMIN_IDON_GEST_ADD: "WEB_ADMIN_IDON_GEST_ADD",
  FEATURE_WEB_ADMIN_IDON_GEST_EDIT: "WEB_ADMIN_IDON_GEST_EDIT",
  FEATURE_WEB_ADMIN_IDON_GEST_DELETE: "WEB_ADMIN_IDON_GEST_DELETE",

  FEATURE_WEB_ADMIN_RISCHI_GEST: "WEB_ADMIN_RISCHI_GEST",
  FEATURE_WEB_ADMIN_RISCHI_GEST_ADD: "WEB_ADMIN_RISCHI_GEST_ADD",
  FEATURE_WEB_ADMIN_RISCHI_GEST_EDIT: "WEB_ADMIN_RISCHI_GEST_EDIT",
  FEATURE_WEB_ADMIN_RISCHI_GEST_DELETE: "WEB_ADMIN_RISCHI_GEST_DELETE",
  // A2A EVOLUTIVE - Req. 2.1 - A. Stefanutti - FINE

  FEATURE_WEB_ADMIN_RUOLI_GEST: "WEB_ADMIN_RUOLI_GEST",
  FEATURE_WEB_ADMIN_RUOLI_GEST_ADD: "WEB_ADMIN_RUOLI_GEST_ADD",
  FEATURE_WEB_ADMIN_RUOLI_GEST_EDIT: "WEB_ADMIN_RUOLI_GEST_EDIT",
  FEATURE_WEB_ADMIN_RUOLI_GEST_DELETE: "WEB_ADMIN_RUOLI_GEST_DELETE",

  FEATURE_WEB_ADMIN_UTENTI_MENU: "WEB_ADMIN_UTENTI_MENU",
  FEATURE_WEB_ADMIN_UTENTI_GEST: "WEB_ADMIN_UTENTI_GEST",
  FEATURE_WEB_ADMIN_UTENTI_GEST_ADD: "WEB_ADMIN_UTENTI_GEST_ADD",
  FEATURE_WEB_ADMIN_UTENTI_GEST_EDIT: "WEB_ADMIN_UTENTI_GEST_EDIT",
  FEATURE_WEB_ADMIN_UTENTI_GEST_DELETE: "WEB_ADMIN_UTENTI_GEST_DELETE",
  FEATURE_WEB_ADMIN_UTENTI_GEST_RESETPWD: "WEB_ADMIN_UTENTI_GEST_RESETPWD",

  FEATURE_WEB_VIEW_LOG_MENU: "WEB_VIEW_LOG_MENU",
  FEATURE_WEB_VIEW_LOG_REG: "WEB_VIEW_LOG_REG",

  FEATURE_WEB_ADMIN_PROFILI_GEST: "WEB_ADMIN_PROFILI_GEST",
  FEATURE_WEB_ADMIN_PROFILI_GEST_ADD: "WEB_ADMIN_PROFILI_GEST_ADD",
  FEATURE_WEB_ADMIN_PROFILI_GEST_EDIT: "WEB_ADMIN_PROFILI_GEST_EDIT",
  FEATURE_WEB_ADMIN_PROFILI_GEST_DELETE: "WEB_ADMIN_PROFILI_GEST_DELETE",

  FEATURE_WEB_ADMIN_SOCIETA_MENU: "WEB_ADMIN_SOCIETA_MENU",
  FEATURE_WEB_ADMIN_SOCIETA_GEST: "WEB_ADMIN_SOCIETA_GEST",
  FEATURE_WEB_ADMIN_SOCIETA_GEST_ADD: "WEB_ADMIN_SOCIETA_GEST_ADD",
  FEATURE_WEB_ADMIN_SOCIETA_GEST_EDIT: "WEB_ADMIN_SOCIETA_GEST_EDIT",
  FEATURE_WEB_ADMIN_SOCIETA_GEST_DELETE: "WEB_ADMIN_SOCIETA_GEST_DELETE",

  FEATURE_WEB_ADMIN_STABIL_GEST: "WEB_ADMIN_STABIL_GEST",
  FEATURE_WEB_ADMIN_STABIL_GEST_ADD: "WEB_ADMIN_STABIL_GEST_ADD",
  FEATURE_WEB_ADMIN_STABIL_GEST_EDIT: "WEB_ADMIN_STABIL_GEST_EDIT",
  FEATURE_WEB_ADMIN_STABIL_GEST_DELETE: "WEB_ADMIN_STABIL_GEST_DELETE",

  FEATURE_WEB_ADMIN_UBIC_GEST: "WEB_ADMIN_UBIC_GEST",
  FEATURE_WEB_ADMIN_UBIC_GEST_ADD: "WEB_ADMIN_UBIC_GEST_ADD",
  FEATURE_WEB_ADMIN_UBIC_GEST_EDIT: "WEB_ADMIN_UBIC_GEST_EDIT",
  FEATURE_WEB_ADMIN_UBIC_GEST_DELETE: "WEB_ADMIN_UBIC_GEST_DELETE",

  FEATURE_WEB_ADMIN_AC_GEST: "WEB_ADMIN_AC_GEST",
  FEATURE_WEB_ADMIN_AC_GEST_ADD: "WEB_ADMIN_AC_GEST_ADD",
  FEATURE_WEB_ADMIN_AC_GEST_EDIT: "WEB_ADMIN_AC_GEST_EDIT",
  FEATURE_WEB_ADMIN_AC_GEST_DELETE: "WEB_ADMIN_AC_GEST_DELETE",

  FEATURE_WEB_ADMIN_ATTREZZ_MENU: "WEB_ADMIN_ATTREZZ_MENU",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_002, REQ_FUN_DPIATTR_003
  // REQ_TEC_DPIATTR_WEB_003, REQ_TEC_DPIATTR_WEB_004
  // Creare Amministrazione classi con wizard e associazione agli attributi.
  FEATURE_WEB_ADMIN_CLASSI_GEST: "WEB_ADMIN_CLASSI_GEST",
  FEATURE_WEB_ADMIN_CLASSI_GEST_ADD: "WEB_ADMIN_CLASSI_GEST_ADD",
  FEATURE_WEB_ADMIN_CLASSI_GEST_EDIT: "WEB_ADMIN_CLASSI_GEST_EDIT",
  FEATURE_WEB_ADMIN_CLASSI_GEST_DELETE: "WEB_ADMIN_CLASSI_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST: "WEB_ADMIN_TIPIATTREZZ_GEST",
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST_ADD: "WEB_ADMIN_TIPIATTREZZ_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST_EDIT: "WEB_ADMIN_TIPIATTREZZ_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIATTREZZ_GEST_DELETE: "WEB_ADMIN_TIPIATTREZZ_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_008, REQ_FUN_DPIATTR_009, REQ_FUN_DPIATTR_010
  // REQ_TEC_DPIATTR_WEB_009, REQ_TEC_DPIATTR_WEB_010, REQ_TEC_DPIATTR_WEB_011
  // Creare Amministrazione modelli.
  FEATURE_WEB_ADMIN_MODELLI_GEST: "WEB_ADMIN_MODELLI_GEST",
  FEATURE_WEB_ADMIN_MODELLI_GEST_ADD: "WEB_ADMIN_MODELLI_GEST_ADD",
  FEATURE_WEB_ADMIN_MODELLI_GEST_EDIT: "WEB_ADMIN_MODELLI_GEST_EDIT",
  FEATURE_WEB_ADMIN_MODELLI_GEST_DELETE: "WEB_ADMIN_MODELLI_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE
  FEATURE_WEB_ADMIN_ATTRIB_GEST: "WEB_ADMIN_ATTRIB_GEST",
  FEATURE_WEB_ADMIN_ATTRIB_GEST_ADD: "WEB_ADMIN_ATTRIB_GEST_ADD",
  FEATURE_WEB_ADMIN_ATTRIB_GEST_EDIT: "WEB_ADMIN_ATTRIB_GEST_EDIT",
  FEATURE_WEB_ADMIN_ATTRIB_GEST_DELETE: "WEB_ADMIN_ATTRIB_GEST_DELETE",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST: "WEB_ADMIN_ATTREZZ_GEST",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST_ADD: "WEB_ADMIN_ATTREZZ_GEST_ADD",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST_EDIT: "WEB_ADMIN_ATTREZZ_GEST_EDIT",
  FEATURE_WEB_ADMIN_ATTREZZ_GEST_DELETE: "WEB_ADMIN_ATTREZZ_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST: "WEB_ADMIN_TIPISCHEDA_GEST",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST_ADD: "WEB_ADMIN_TIPISCHEDA_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST_EDIT: "WEB_ADMIN_TIPISCHEDA_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPISCHEDA_GEST_DELETE: "WEB_ADMIN_TIPISCHEDA_GEST_DELETE",
  FEATURE_WEB_ADMIN_TASKS_GEST: "WEB_ADMIN_TASKS_GEST",
  FEATURE_WEB_ADMIN_TASKS_GEST_ADD: "WEB_ADMIN_TASKS_GEST_ADD",
  FEATURE_WEB_ADMIN_TASKS_GEST_EDIT: "WEB_ADMIN_TASKS_GEST_EDIT",
  FEATURE_WEB_ADMIN_TASKS_GEST_DELETE: "WEB_ADMIN_TASKS_GEST_DELETE",

  FEATURE_WEB_ADMIN_ZONE_GEST: "WEB_ADMIN_ZONE_GEST",
  FEATURE_WEB_ADMIN_ZONE_GEST_ADD: "WEB_ADMIN_ZONE_GEST_ADD",
  FEATURE_WEB_ADMIN_ZONE_GEST_EDIT: "WEB_ADMIN_ZONE_GEST_EDIT",
  FEATURE_WEB_ADMIN_ZONE_GEST_DELETE: "WEB_ADMIN_ZONE_GEST_DELETE",

  FEATURE_WEB_ADMIN_ORDINI_GEST: "WEB_ADMIN_ORDINI_GEST",
  FEATURE_WEB_ADMIN_ORDINI_GEST_ADD: "WEB_ADMIN_ORDINI_GEST_ADD",
  FEATURE_WEB_ADMIN_ORDINI_GEST_EDIT: "WEB_ADMIN_ORDINI_GEST_EDIT",
  FEATURE_WEB_ADMIN_ORDINI_GEST_DELETE: "WEB_ADMIN_ORDINI_GEST_DELETE",

  FEATURE_WEB_ADMIN_CONFIG_GEST: "WEB_ADMIN_CONFIG_GEST",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_BB: "WEB_ADMIN_CONFIG_GEST_LIST_BB",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_COS: "WEB_ADMIN_CONFIG_GEST_LIST_COS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_COSRT: "WEB_ADMIN_CONFIG_GEST_LIST_COSRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_LDC: "WEB_ADMIN_CONFIG_GEST_LIST_LDC",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_MM: "WEB_ADMIN_CONFIG_GEST_LIST_MM",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SAW: "WEB_ADMIN_CONFIG_GEST_LIST_SAW",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SAWRT: "WEB_ADMIN_CONFIG_GEST_LIST_SAWRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SSIGN: "WEB_ADMIN_CONFIG_GEST_LIST_SSIGN",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SSIGNRT: "WEB_ADMIN_CONFIG_GEST_LIST_SSIGNRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_SYS: "WEB_ADMIN_CONFIG_GEST_LIST_SYS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LIST_UAT: "WEB_ADMIN_CONFIG_GEST_LIST_UAT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_BB: "WEB_ADMIN_CONFIG_GEST_EDIT_BB",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_COS: "WEB_ADMIN_CONFIG_GEST_EDIT_COS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_COSRT: "WEB_ADMIN_CONFIG_GEST_EDIT_COSRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_LDC: "WEB_ADMIN_CONFIG_GEST_EDIT_LDC",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_MM: "WEB_ADMIN_CONFIG_GEST_EDIT_MM",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SAW: "WEB_ADMIN_CONFIG_GEST_EDIT_SAW",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SAWRT: "WEB_ADMIN_CONFIG_GEST_EDIT_SAWRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SSIGN: "WEB_ADMIN_CONFIG_GEST_EDIT_SSIGN",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SSIGNRT: "WEB_ADMIN_CONFIG_GEST_EDIT_SSIGNRT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_SYS: "WEB_ADMIN_CONFIG_GEST_EDIT_SYS",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT_UAT: "WEB_ADMIN_CONFIG_GEST_EDIT_UAT",
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE
  FEATURE_WEB_ADMIN_CONFIG_GEST_ADD: "WEB_ADMIN_CONFIG_GEST_ADD",
  FEATURE_WEB_ADMIN_CONFIG_GEST_EDIT: "WEB_ADMIN_CONFIG_GEST_EDIT",
  FEATURE_WEB_ADMIN_CONFIG_GEST_DELETE: "WEB_ADMIN_CONFIG_GEST_DELETE",
  FEATURE_WEB_ADMIN_CONFIG_GEST_LICENSE: "WEB_ADMIN_CONFIG_GEST_LICENSE",
  // A. Stefanutti - Cancellazione dati obsoleti (trello https://trello.com/c/hqYb423c)
  FEATURE_WEB_ADMIN_CONFIG_STARTSTOP: "WEB_ADMIN_CONFIG_STARTSTOP",
  FEATURE_WEB_OPER_DATADELETE: "WEB_OPER_DATADELETE",
  FEATURE_AZIONE_DATADELETE_START: "AZIONE_DATADELETE_START",
  FEATURE_AZIONE_DATADELETE_STOP: "AZIONE_DATADELETE_STOP",
  FEATURE_AZIONE_DATADELETE_RESTART: "AZIONE_DATADELETE_RESTART",
  // A. Stefanutti - Cancellazione dati obsoleti (trello https://trello.com/c/hqYb423c) - FINE

  FEATURE_WEB_ADMIN_TIPIOP_GEST: "WEB_ADMIN_TIPIOP_GEST",
  FEATURE_WEB_ADMIN_TIPIOP_GEST_ADD: "WEB_ADMIN_TIPIOP_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIOP_GEST_EDIT: "WEB_ADMIN_TIPIOP_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIOP_GEST_DELETE: "WEB_ADMIN_TIPIOP_GEST_DELETE",
  
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE
  // REQ_FUN_DPIATTR_001
  // REQ_TEC_DPIATTR_WEB_001
  // Modificare Amministrazione tipi campo (dato) - lasciare SOLO vista tabellare
  // - Rinominato tutto in "Dato" invece di "Campo"
  FEATURE_WEB_ADMIN_TIPIDATO_GEST: "WEB_ADMIN_TIPIDATO_GEST",
  FEATURE_WEB_ADMIN_TIPIDATO_GEST_ADD: "WEB_ADMIN_TIPIDATO_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIDATO_GEST_EDIT: "WEB_ADMIN_TIPIDATO_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIDATO_GEST_DELETE: "WEB_ADMIN_TIPIDATO_GEST_DELETE",
  // A. Stefanutti - Confluenza DPI in ATTREZZATURE - FINE

  FEATURE_WEB_ADMIN_TIPIATTIV_GEST: "WEB_ADMIN_TIPIATTIV_GEST",
  FEATURE_WEB_ADMIN_TIPIATTIV_GEST_ADD: "WEB_ADMIN_TIPIATTIV_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIATTIV_GEST_EDIT: "WEB_ADMIN_TIPIATTIV_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIATTIV_GEST_DELETE: "WEB_ADMIN_TIPIATTIV_GEST_DELETE",

  FEATURE_WEB_ADMIN_ALLARMI_MENU: "WEB_ADMIN_ALLARMI_MENU",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST: "WEB_ADMIN_TIPIALLARME_GEST",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST_ADD: "WEB_ADMIN_TIPIALLARME_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST_EDIT: "WEB_ADMIN_TIPIALLARME_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIALLARME_GEST_DELETE: "WEB_ADMIN_TIPIALLARME_GEST_EDIT",
  FEATURE_WEB_ADMIN_SOLUZ_GEST: "WEB_ADMIN_SOLUZ_GEST",
  FEATURE_WEB_ADMIN_SOLUZ_GEST_ADD: "WEB_ADMIN_SOLUZ_GEST_ADD",
  FEATURE_WEB_ADMIN_SOLUZ_GEST_EDIT: "WEB_ADMIN_SOLUZ_GEST_DELETE",
  FEATURE_WEB_ADMIN_SOLUZ_GEST_DELETE: "WEB_ADMIN_SOLUZ_GEST_EDIT",

  FEATURE_WEB_ADMIN_LIVLOG_GEST: "WEB_ADMIN_LIVLOG_GEST",
  FEATURE_WEB_ADMIN_LIVLOG_GEST_ADD: "WEB_ADMIN_LIVLOG_GEST_ADD",
  FEATURE_WEB_ADMIN_LIVLOG_GEST_EDIT: "WEB_ADMIN_LIVLOG_GEST_DELETE",
  FEATURE_WEB_ADMIN_LIVLOG_GEST_DELETE: "WEB_ADMIN_LIVLOG_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_PROT_GEST: "WEB_ADMIN_PROT_GEST",
  FEATURE_WEB_ADMIN_PROT_GEST_ADD: "WEB_ADMIN_PROT_GEST_ADD",
  FEATURE_WEB_ADMIN_PROT_GEST_EDIT: "WEB_ADMIN_PROT_GEST_DELETE",
  FEATURE_WEB_ADMIN_PROT_GEST_DELETE: "WEB_ADMIN_PROT_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_TECN_GEST: "WEB_ADMIN_TECN_GEST",
  FEATURE_WEB_ADMIN_TECN_GEST_ADD: "WEB_ADMIN_TECN_GEST_ADD",
  FEATURE_WEB_ADMIN_TECN_GEST_EDIT: "WEB_ADMIN_TECN_GEST_DELETE",
  FEATURE_WEB_ADMIN_TECN_GEST_DELETE: "WEB_ADMIN_TECN_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPIASS_GEST: "WEB_ADMIN_TIPIASS_GEST",
  FEATURE_WEB_ADMIN_TIPIASS_GEST_ADD: "WEB_ADMIN_TIPIASS_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIASS_GEST_EDIT: "WEB_ADMIN_TIPIASS_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIASS_GEST_DELETE: "WEB_ADMIN_TIPIASS_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPILAV_GEST: "WEB_ADMIN_TIPILAV_GEST",
  FEATURE_WEB_ADMIN_TIPILAV_GEST_ADD: "WEB_ADMIN_TIPILAV_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPILAV_GEST_EDIT: "WEB_ADMIN_TIPILAV_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPILAV_GEST_DELETE: "WEB_ADMIN_TIPILAV_GEST_EDIT",

  FEATURE_WEB_ADMIN_DPI_MENU: "WEB_ADMIN_DPI_MENU",
  FEATURE_WEB_ADMIN_ASS_GEST: "WEB_ADMIN_ASS_GEST",
  FEATURE_WEB_ADMIN_ASS_GEST_ADD: "WEB_ADMIN_ASS_GEST_ADD",
  FEATURE_WEB_ADMIN_ASS_GEST_EDIT: "WEB_ADMIN_ASS_GEST_DELETE",
  FEATURE_WEB_ADMIN_ASS_GEST_DELETE: "WEB_ADMIN_ASS_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPITELEM_GEST: "WEB_ADMIN_TIPITELEM_GEST",
  FEATURE_WEB_ADMIN_TIPITELEM_GEST_ADD: "WEB_ADMIN_TIPITELEM_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPITELEM_GEST_EDIT: "WEB_ADMIN_TIPITELEM_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPITELEM_GEST_DELETE: "WEB_ADMIN_TIPITELEM_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPIDISP_GEST: "WEB_ADMIN_TIPIDISP_GEST",
  FEATURE_WEB_ADMIN_TIPIDISP_GEST_ADD: "WEB_ADMIN_TIPIDISP_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIDISP_GEST_EDIT: "WEB_ADMIN_TIPIDISP_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIDISP_GEST_DELETE: "WEB_ADMIN_TIPIDISP_GEST_EDIT",

  FEATURE_WEB_ADMIN_DISP_GEST: "WEB_ADMIN_DISP_GEST",
  FEATURE_WEB_ADMIN_DISP_GEST_ADD: "WEB_ADMIN_DISP_GEST_ADD",
  FEATURE_WEB_ADMIN_DISP_GEST_EDIT: "WEB_ADMIN_DISP_GEST_DELETE",
  FEATURE_WEB_ADMIN_DISP_GEST_DELETE: "WEB_ADMIN_DISP_GEST_EDIT",

  FEATURE_WEB_ADMIN_MEZZI_MENU: "WEB_ADMIN_MEZZI_MENU",
  FEATURE_WEB_ADMIN_MEZZI_GEST: "WEB_ADMIN_MEZZI_GEST",
  FEATURE_WEB_ADMIN_MEZZI_GEST_ADD: "WEB_ADMIN_MEZZI_GEST_ADD",
  FEATURE_WEB_ADMIN_MEZZI_GEST_EDIT: "WEB_ADMIN_MEZZI_GEST_DELETE",
  FEATURE_WEB_ADMIN_MEZZI_GEST_DELETE: "WEB_ADMIN_MEZZI_GEST_EDIT",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST: "WEB_ADMIN_TIPIMEZZI_GEST",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST_ADD: "WEB_ADMIN_TIPIMEZZI_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST_EDIT: "WEB_ADMIN_TIPIMEZZI_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIMEZZI_GEST_DELETE: "WEB_ADMIN_TIPIMEZZI_GEST_EDIT",

  FEATURE_WEB_ADMIN_ATT_MENU: "WEB_ADMIN_ATT_MENU",
  FEATURE_WEB_ADMIN_ATT_GEST: "WEB_ADMIN_ATT_GEST",
  FEATURE_WEB_ADMIN_ATT_GEST_ADD: "WEB_ADMIN_ATT_GEST_ADD",
  FEATURE_WEB_ADMIN_ATT_GEST_EDIT: "WEB_ADMIN_ATT_GEST_DELETE",
  FEATURE_WEB_ADMIN_ATT_GEST_DELETE: "WEB_ADMIN_ATT_GEST_EDIT",
  FEATURE_WEB_ADMIN_LAV_GEST: "WEB_ADMIN_LAV_GEST",
  FEATURE_WEB_ADMIN_LAV_GEST_ADD: "WEB_ADMIN_LAV_GEST_ADD",
  FEATURE_WEB_ADMIN_LAV_GEST_EDIT: "WEB_ADMIN_LAV_GEST_DELETE",
  FEATURE_WEB_ADMIN_LAV_GEST_DELETE: "WEB_ADMIN_LAV_GEST_EDIT",

  FEATURE_WEB_ADMIN_SITI_MENU: "WEB_ADMIN_SITI_MENU",
  FEATURE_WEB_ADMIN_SITI_GEST: "WEB_ADMIN_SITI_GEST",
  FEATURE_WEB_ADMIN_SITI_GEST_ADD: "WEB_ADMIN_SITI_GEST_ADD",
  FEATURE_WEB_ADMIN_SITI_GEST_EDIT: "WEB_ADMIN_SITI_GEST_DELETE",
  FEATURE_WEB_ADMIN_SITI_GEST_DELETE: "WEB_ADMIN_SITI_GEST_EDIT",
  FEATURE_WEB_ADMIN_AREE_GEST: "WEB_ADMIN_AREE_GEST",
  FEATURE_WEB_ADMIN_AREE_GEST_ADD: "WEB_ADMIN_AREE_GEST_ADD",
  FEATURE_WEB_ADMIN_AREE_GEST_EDIT: "WEB_ADMIN_AREE_GEST_DELETE",
  FEATURE_WEB_ADMIN_AREE_GEST_DELETE: "WEB_ADMIN_AREE_GEST_EDIT",

  FEATURE_WEB_ADMIN_TIPICANTIERE_GEST: "WEB_ADMIN_YARDKIND_GEST",
  FEATURE_WEB_ADMIN_TIPICANTIERE_GEST_ADD: "WEB_ADMIN_YARDKIND_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPICANTIERE_GEST_EDIT: "WEB_ADMIN_YARDKIND_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPICANTIERE_GEST_DELETE: "WEB_ADMIN_YARDKIND_GEST_EDIT",

  FEATURE_WEB_ADMIN_CANTIERI_GEST: "WEB_ADMIN_CANT_GEST",
  FEATURE_WEB_ADMIN_CANTIERI_GEST_ADD: "WEB_ADMIN_CANT_GEST_ADD",
  FEATURE_WEB_ADMIN_CANTIERI_GEST_EDIT: "WEB_ADMIN_CANT_GEST_DELETE",
  FEATURE_WEB_ADMIN_CANTIERI_GEST_DELETE: "WEB_ADMIN_CANT_GEST_EDIT",

  FEATURE_WEB_ADMIN_STATICANTIERE_GEST: "WEB_ADMIN_CANT_STATI_GEST",
  FEATURE_WEB_ADMIN_STATICANTIERE_GEST_ADD: "WEB_ADMIN_CANT_STATI_GEST_ADD",
  FEATURE_WEB_ADMIN_STATICANTIERE_GEST_EDIT: "WEB_ADMIN_CANT_STATI_GEST_DELETE",
  FEATURE_WEB_ADMIN_STATICANTIERE_GEST_DELETE: "WEB_ADMIN_CANT_STATI_GEST_EDIT",

  FEATURE_WEB_ADMIN_SOTTOSTATICANTIERE_GEST: "WEB_ADMIN_CANT_SOTSTATI_GEST",
  FEATURE_WEB_ADMIN_SOTTOSTATICANTIERE_GEST_ADD: "WEB_ADMIN_CANT_SOTSTATI_GEST_ADD",
  FEATURE_WEB_ADMIN_SOTTOSTATICANTIERE_GEST_EDIT: "WEB_ADMIN_CANT_SOTSTATI_GEST_DELETE",
  FEATURE_WEB_ADMIN_SOTTOSTATICANTIERE_GEST_DELETE: "WEB_ADMIN_CANT_SOTSTATI_GEST_EDIT",

  FEATURE_WEB_ADMIN_STATIUTENTE_GEST: "WEB_ADMIN_UTENTI_STATI_GEST",
  FEATURE_WEB_ADMIN_STATIUTENTE_GEST_ADD: "WEB_ADMIN_UTENTI_STATI_GEST_ADD",
  FEATURE_WEB_ADMIN_STATIUTENTE_GEST_EDIT: "WEB_ADMIN_UTENTI_STATI_GEST_DELETE",
  FEATURE_WEB_ADMIN_STATIUTENTE_GEST_DELETE: "WEB_ADMIN_UTENTI_STATI_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_STATIMEZZO_GEST: "WEB_ADMIN_MEZZI_STATI_GEST",
  FEATURE_WEB_ADMIN_STATIMEZZO_GEST_ADD: "WEB_ADMIN_MEZZI_STATI_GEST_ADD",
  FEATURE_WEB_ADMIN_STATIMEZZO_GEST_EDIT: "WEB_ADMIN_MEZZI_STATI_GEST_DELETE",
  FEATURE_WEB_ADMIN_STATIMEZZO_GEST_DELETE: "WEB_ADMIN_MEZZI_STATI_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_STATIATTREZZATURA_GEST: "WEB_ADMIN_ATTREZZ_STATI_GEST",
  FEATURE_WEB_ADMIN_STATIATTREZZATURA_GEST_ADD: "WEB_ADMIN_ATTREZZ_STATI_GEST_ADD",
  FEATURE_WEB_ADMIN_STATIATTREZZATURA_GEST_EDIT: "WEB_ADMIN_ATTREZZ_STATI_GEST_DELETE",
  FEATURE_WEB_ADMIN_STATIATTREZZATURA_GEST_DELETE: "WEB_ADMIN_ATTREZZ_STATI_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_TIPIACQUISIZIONE_GEST: "WEB_ADMIN_TIPIACQUIS_GEST",
  FEATURE_WEB_ADMIN_TIPIACQUISIZIONE_GEST_ADD: "WEB_ADMIN_TIPIACQUIS_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIACQUISIZIONE_GEST_EDIT: "WEB_ADMIN_TIPIACQUIS_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIACQUISIZIONE_GEST_DELETE: "WEB_ADMIN_TIPIACQUIS_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_TIPIRILEVAMENTOPRESENZA_GEST: "WEB_ADMIN_TIPIRILPRES_GEST",
  FEATURE_WEB_ADMIN_TIPIRILEVAMENTOPRESENZA_GEST_ADD: "WEB_ADMIN_TIPIRILPRES_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPIRILEVAMENTOPRESENZA_GEST_EDIT: "WEB_ADMIN_TIPIRILPRES_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPIRILEVAMENTOPRESENZA_GEST_DELETE: "WEB_ADMIN_TIPIRILPRES_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_CONTROLLI_MENU: "WEB_ADMIN_CHK_MENU",
  FEATURE_WEB_ADMIN_TIPICONTROLLO_GEST: "WEB_ADMIN_CHKKIND_GEST",
  FEATURE_WEB_ADMIN_TIPICONTROLLO_GEST_ADD: "WEB_ADMIN_CHKKIND_GEST_ADD",
  FEATURE_WEB_ADMIN_TIPICONTROLLO_GEST_EDIT: "WEB_ADMIN_CHKKIND_GEST_DELETE",
  FEATURE_WEB_ADMIN_TIPICONTROLLO_GEST_DELETE: "WEB_ADMIN_CHKKIND_GEST_EDIT",
  FEATURE_WEB_ADMIN_ESITICONTROLLO_GEST: "WEB_ADMIN_CHKOUT_GEST",
  FEATURE_WEB_ADMIN_ESITICONTROLLO_GEST_ADD: "WEB_ADMIN_CHKOUT_GEST_ADD",
  FEATURE_WEB_ADMIN_ESITICONTROLLO_GEST_EDIT: "WEB_ADMIN_CHKOUT_GEST_DELETE",
  FEATURE_WEB_ADMIN_ESITICONTROLLO_GEST_DELETE: "WEB_ADMIN_CHKOUT_GEST_EDIT",
  
  FEATURE_WEB_ADMIN_QUESTIONARI_MENU: "WEB_ADMIN_SURVEY_MENU",
  FEATURE_WEB_ADMIN_QUESTIONARI_DOMANDE_GEST: "WEB_ADMIN_SURVEY_Q_GEST",
  FEATURE_WEB_ADMIN_QUESTIONARI_DOMANDE_GEST_ADD: "WEB_ADMIN_SURVEY_Q_GEST_ADD",
  FEATURE_WEB_ADMIN_QUESTIONARI_DOMANDE_GEST_EDIT: "WEB_ADMIN_SURVEY_Q_GEST_DELETE",
  FEATURE_WEB_ADMIN_QUESTIONARI_DOMANDE_GEST_DELETE: "WEB_ADMIN_SURVEY_Q_GEST_EDIT",
  FEATURE_WEB_ADMIN_QUESTIONARI_GRUPPIDOMANDE_GEST: "WEB_ADMIN_SURVEY_QG_GEST",
  FEATURE_WEB_ADMIN_QUESTIONARI_GRUPPIDOMANDE_GEST_ADD: "WEB_ADMIN_SURVEY_QG_GEST_ADD",
  FEATURE_WEB_ADMIN_QUESTIONARI_GRUPPIDOMANDE_GEST_EDIT: "WEB_ADMIN_SURVEY_QG_GEST_DELETE",
  FEATURE_WEB_ADMIN_QUESTIONARI_GRUPPIDOMANDE_GEST_DELETE: "WEB_ADMIN_SURVEY_QG_GEST_EDIT",
  FEATURE_WEB_ADMIN_QUESTIONARI_TIPI_GEST: "WEB_ADMIN_SURVEY_K_GEST",
  FEATURE_WEB_ADMIN_QUESTIONARI_TIPI_GEST_ADD: "WEB_ADMIN_SURVEY_K_GEST_ADD",
  FEATURE_WEB_ADMIN_QUESTIONARI_TIPI_GEST_EDIT: "WEB_ADMIN_SURVEY_K_GEST_DELETE",
  FEATURE_WEB_ADMIN_QUESTIONARI_TIPI_GEST_DELETE: "WEB_ADMIN_SURVEY_K_GEST_EDIT",
  FEATURE_WEB_ADMIN_QUESTIONARI_RIPOSTE_GEST: "WEB_ADMIN_SURVEY_A_GEST",
  FEATURE_WEB_ADMIN_QUESTIONARI_RIPOSTE_GEST_ADD: "WEB_ADMIN_SURVEY_A_GEST_ADD",
  FEATURE_WEB_ADMIN_QUESTIONARI_RIPOSTE_GEST_EDIT: "WEB_ADMIN_SURVEY_A_GEST_DELETE",
  FEATURE_WEB_ADMIN_QUESTIONARI_RIPOSTE_GEST_DELETE: "WEB_ADMIN_SURVEY_A_GEST_EDIT",
  FEATURE_WEB_ADMIN_QUESTIONARI_VERSIONI_GEST: "WEB_ADMIN_SURVEY_V_GEST",
  FEATURE_WEB_ADMIN_QUESTIONARI_VERSIONI_GEST_ADD: "WEB_ADMIN_SURVEY_V_GEST_ADD",
  FEATURE_WEB_ADMIN_QUESTIONARI_VERSIONI_GEST_EDIT: "WEB_ADMIN_SURVEY_V_GEST_DELETE",
  FEATURE_WEB_ADMIN_QUESTIONARI_VERSIONI_GEST_DELETE: "WEB_ADMIN_SURVEY_V_GEST_EDIT",

  TIPIDPI_DURATA_DEFAULT: 12,

  MODE_ADD: 1,
  MODE_EDIT: 2,
  MODE_VIEW: 3,

  DEFAULT_PAGE_SIZE: 10, // [5, 10, 20, 25, 50, 100]
  DEFAULT_ORDERS_PAGE_SIZE: 5, // [5, 10, 20, 25, 50, 100]

  MSG_KIND_INFO: 1,
  MSG_KIND_SUCCESS: 2,
  MSG_KIND_WARNING: 3,
  MSG_KIND_ERROR: 4,
  MSG_KIND_PRIMARY: 5,
  MSG_KIND_NOTE: 6,

  PROFILE_CODE_SYSADMIN: "SYSADMIN",

  ROLE_CODE_SYSADMIN: "SYSADMIN",
  ROLE_CODE_CLISUP: "CLISUP",
  ROLE_CODE_GESTORDINIADMIN: "GESTORDINIADMIN",
  ROLE_CODE_GESTORDINIAC: "GESTORDINIAC",
  ROLE_CODE_GESTORDINIPIVA: "GESTORDINIPIVA",
  ROLE_CODE_GESTORDINIMANUT: "GESTORDINIMANUT",
  ROLE_CODE_CLIMOB: "CLIMOB",
  ROLE_CODE_MANUMOB: "MANUMOB",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti
  // Report utenti che non hanno fatto il check quotidiano (senza interazione con schedulatore A2A).
  ROLE_CODE_REPORTSALLZONES: "REPORTSALLZONES",
  // A2A EVOLUTIVE - Req. 3 - A. Stefanutti - FINE

  CODICE_ESITO_CONFORME: "CONFORME",
  CODICE_ESITO_NONCONFORME: "NON CONFORME",
  CODICE_ESITO_NONAPPLICABILE: "NON APPLICABILE",

  CODICE_ESITO_CONTROLLI_OK: "ES001",
  CODICE_ESITO_CONTROLLI_KO: "ES002",
  CODICE_ESITO_CONTROLLI_SOSP: "ES003",
  CODICE_ESITO_CONTROLLI_KO_DPI_ALL: "ES004",
  CODICE_ESITO_CONTROLLI_KO_DPI_NON_PRES: "ES005",
  CODICE_ESITO_CONTROLLI_KO_DPI_NON_RIL: "ES006",

  CODICE_TIPO_CAMPO_BOOLEAN: "BOOL",    // Da DB
  CODICE_TIPO_CAMPO_TESTO: "TXT",       // Da DB
  // A. Stefanutti - Sostituzione tipo dato "NUM" con la coppia "INT" e "DECIMAL" (trello https://trello.com/c/GbPTmWxI)
  // Rimosso il tipo di campo numerico generico.
  CODICE_TIPO_CAMPO_DATAORA: "DATAORA", // Da DB
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq)
  CODICE_TIPO_CAMPO_INT: "INT",         // Da DB
  CODICE_TIPO_CAMPO_DECIMAL: "DECIMAL", // Da DB
  // A. Stefanutti - Configurazione delle app mobile da remoto (trello https://trello.com/c/VgkLg3oq) - FINE

  TIPOASSEGNATARIO_CODE_AC: "AREACENTER",
  TIPOASSEGNATARIO_CODE_PIVA: "PARTITAIVA",
  
  LOGIN_REDIRECT_TIMEOUT: 6000, // millis
  RECUPERO_PWD_TIMEOUT: 10000, // millis

  ORDER_STATUS_CODE_BOZZA: "BOZZA",
  ORDER_STATUS_CODE_NUOVO: "NUOVO",
  ORDER_STATUS_CODE_ASSEGNATO: "ASSEGNATO",
  ORDER_STATUS_CODE_ACCETTATO: "ACCETTATO",
  ORDER_STATUS_CODE_ACCETTATOAC: "ACCETTATOAC",
  ORDER_STATUS_CODE_INCORSO: "INCORSO",
  ORDER_STATUS_CODE_COMPLETATO: "COMPLETATO",
  ORDER_STATUS_CODE_RIFIUTATO: "RIFIUTATO",
  ORDER_STATUS_CODE_CANCELLATO: "CANCELLATO",

  CODICE_STATO_INTERVENTO_NUOVO: "NUOVO",
  CODICE_STATO_INTERVENTO_COMPLETATO: "COMPLETATO",
  CODICE_SITUAZIONE_INTERVENTO_REGOLARE: 0,
  CODICE_SITUAZIONE_INTERVENTO_INSCADENZA: 100, // Secondo i criteri implementati nel Backend
  CODICE_SITUAZIONE_INTERVENTO_INRITARDO: 200,  // Eseguito in data successiva alla data di scadenza
  CODICE_SITUAZIONE_INTERVENTO_SCADUTO: 300,    // Non ancora eseguito e con data di scadenza nel passato
  
  DEVICEKIND_CODE_DPI_TAG: "TAG",
  DEVICEKIND_CODE_DPI_NFC_TAG: "PPENFCTAG",
  DEVICEKIND_CODE_SMARTSIGN_TAG: "SSTAG",
  DEVICEKIND_CODE_SMARTSIGN_GW: "SSGW",
  DEVICEKIND_CODE_SMARTPHONE: "SMARTPA",
	DEVICEKIND_CODE_ABWTRACKER: "ABWTRACKER",
	DEVICEKIND_CODE_EMUTRACKER: "EMUTRACKER",
  DEVICEKIND_CODE_NBTRACKER: "NBTRACKER",
  
  TIPODPI_BELT: "BELT",
  TIPODPI_EARPROTECTORS: "EARPROTECTORS",
  TIPODPI_GAS: "GAS",
  TIPODPI_GLASSES: "GLASSES",
  TIPODPI_GLASSES_DUST: "GLASSES_DUST",
  TIPODPI_GLOVEDX: "GLOVEDX",
  TIPODPI_GLOVEDXNI: "GLOVEDXNI",
  TIPODPI_GLOVESX: "GLOVESX",
  TIPODPI_GLOVESXNI: "GLOVESXNI",
  TIPODPI_HARNESS: "HARNESS",
  TIPODPI_HAT: "HAT",
  TIPODPI_HELMET: "HELMET",
  TIPODPI_HELMETNV: "HELMETNV",
  TIPODPI_JACKET: "JACKET",
  TIPODPI_PANTS: "PANTS",
  TIPODPI_SHOES: "SHOES",
  TIPODPI_UOMOAT: "UOMOAT",
  TIPODPI_VISOR: "VISOR",
  TIPODPI_UNKNOWN: "UNKNOWN",
  // A. Stefanutti - POC OLI TIM APP SMART DPI AUTOREGISTRAZIONE E ICONE (trello https://trello.com/c/lRssRSPi)
  TIPODPI_KPRSOCC: "KPRSOCC",
  TIPODPI_KRABB: "KRABB",
  TIPODPI_LANYARD: "LANYARD",
  // A. Stefanutti - POC OLI TIM APP SMART DPI AUTOREGISTRAZIONE E ICONE (trello https://trello.com/c/lRssRSPi) - FINE
  // A. Stefanutti - Introduzione nuovo tipo di DPI "esplosimetro"
  TIPODPI_EXPLOSIMETER: "EXPLOSIMETER",
  // A. Stefanutti - Introduzione nuovo tipo di DPI "esplosimetro" - FINE

  SOLUZIONEALLARME_AUTO: "AUTO",
  SOLUZIONEALLARME_END: "END",
  SOLUZIONEALLARME_FALSE: "FALSE",
  SOLUZIONEALLARME_IGNORE: "IGNORE",
  SOLUZIONEALLARME_SUSPEND: "SUSPEND",
  SOLUZIONEALLARME_OK: "OK",
  SOLUZIONEALLARME_INCHARGE: "INCHARGE",
  SOLUZIONEALLARME_INCHARGE_NOME: "Allarme preso in carico",
  // A. Stefanutti - Allarmi e notifiche Geofencing, fase I (trello https://trello.com/c/1gQdCDTc)
  SOLUZIONEALLARME_GFC_OUT: "GFC_OUT",
  SOLUZIONEALLARME_GFC_IGNORE: "GFC_IGNORE",
  SOLUZIONEALLARME_GFC_INCHARGE: "GFC_INCHARGE",
  SOLUZIONEALLARME_GFC_TIMEOUT: "GFC_TIMEOUT",
  SOLUZIONEALLARME_GFC_DATAEXPIRED: "GFC_DATAEXPIRED",
  // A. Stefanutti - Allarmi e notifiche Geofencing, fase I (trello https://trello.com/c/1gQdCDTc) - FINE
  SOLUZIONEALLARME_GFC_RESTART: "GFC_RESTART",
  // A. Stefanutti - Conteggio nuovi tipi di soluzione SAPPE (https://trello.com/c/nrfSkEpo)
  SOLUZIONEALLARME_CHANGELAY: "CHANGELAY",
  SOLUZIONEALLARME_INCOMING_UAT: "INCOMING_UAT",
  SOLUZIONEALLARME_OPER_NOT_PRESENT: "OPER_NOT_PRESENT",
  SOLUZIONEALLARME_INCOMING_OFFLINE: "INCOMING_OFFLINE",
  // A. Stefanutti - Conteggio nuovi tipi di soluzione SAPPE (https://trello.com/c/nrfSkEpo) - FINE
  SOLUZIONEALLARME_SOLUZIONEOP: "OPERATOR",
  ALERT_KIND_CODE_PPE: "PPE",
  // A. Stefanutti - Aggiunta della gestione tipo allarme PPEOFFLINE
  ALERT_KIND_CODE_PPEOFFLINE: "PPEOFFLINE",
  // A. Stefanutti - Aggiunta della gestione tipo allarme PPEOFFLINE - FINE
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.1 Allarme sensore UAT “out of range” e gestione differenziata allarmi DPI + 3.1.2 Allarme "silente" (https://trello.com/c/R9UrAJOk)
  ALERT_KIND_CODE_PPEOUT: "PPEOUT",
  ALERT_KIND_CODE_PPESTEADY: "PPESTEADY",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.1 Allarme sensore UAT “out of range” e gestione differenziata allarmi DPI + 3.1.2 Allarme "silente" (https://trello.com/c/R9UrAJOk) - FINE
  ALERT_KIND_CODE_SMS: "SMS",
  ALERT_KIND_CODE_UAT: "UAT",
  // A. Stefanutti - Man Down enhancement (trello https://trello.com/c/tJFZEjfJ)
  // Gestione e visualizzazione dei nuovi tipi di allarme UAT (con caduta, con impatto, con caduta e impatto)
  ALERT_KIND_CODE_UATI: "UATI",
  ALERT_KIND_CODE_UATC: "UATC",
  ALERT_KIND_CODE_UATIC: "UATIC",
  // A. Stefanutti - Man Down enhancement (trello https://trello.com/c/tJFZEjfJ) - FINE
  // A. Stefanutti - Allarmi e notifiche Geofencing, fase I (trello https://trello.com/c/1gQdCDTc)
  ALERT_KIND_CODE_GFC: "GFC",
  // A. Stefanutti - Allarmi e notifiche Geofencing, fase I (trello https://trello.com/c/1gQdCDTc) - FINE
  // A. Stefanutti - Gestione differenziata tipi allarme per tag Man Down o DPI (https://trello.com/c/a8HQzdSX)
  ALERT_KIND_CODE_PPEUAT: "PPEUAT",
  ALERT_KIND_CODE_PPEOFFLINEUAT: "PPEOFFLINEUAT",
  ALERT_KIND_CODE_PPEOUTUAT: "PPEOUTUAT",
  ALERT_KIND_CODE_PPESTEADYUAT: "PPESTEADYUAT",
  // A. Stefanutti - Gestione differenziata tipi allarme per tag Man Down o DPI (https://trello.com/c/a8HQzdSX) - FINE
  TIPOCONTROLLO_CHECKQUOT: "SAWCHECKQUOTID",
  TIPOCONTROLLO_CHECKMULTIOP: "SAWCHECKMULTIOP",

  TIPOLAVOROESEGUITO_MONO: 0,
  TIPOLAVOROESEGUITO_MULTI: 1,

  RAVICA_USERSTATUS_CODE_PRES: "PRES",
  RAVICA_USERSTATUS_CODE_OPER: "OPER",
  RAVICA_USERSTATUS_CODE_ATTE: "ATTE",
  RAVICA_USERSTATUS_CODE_ASSE: "ASSE",
  
  RAVICA_MEANSTATUS_CODE_PRES: "PRES",
  RAVICA_MEANSTATUS_CODE_OPER: "OPER",
  RAVICA_MEANSTATUS_CODE_ATTE: "ATTE",
  RAVICA_MEANSTATUS_CODE_ASSE: "ASSE",

  RAVICA_TOOLSTATUS_CODE_PRES: "PRES",
  RAVICA_TOOLSTATUS_CODE_OPER: "OPER",
  RAVICA_TOOLSTATUS_CODE_ATTE: "ATTE",
  RAVICA_TOOLSTATUS_CODE_ASSE: "ASSE",

  DUMMY_STRING: "UNKNOWN",

  // A. Stefanutti - Disabilitazione input data scadenza per attrezzature di classe DPI (trello https://trello.com/c/o98Vuzaf).
  CLASS_CODE_DPI: "DPI",
  // A. Stefanutti - Disabilitazione input data scadenza per attrezzature di classe DPI - FINE

  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC)
  STATUS_CODE_ATTIVO: "ATTIVO",
  STATUS_CODE_CANCELLATO: "CANCELLATO",
  STATUS_CODE_DISMESSO: "DISMESSO",
  // A. Stefanutti - POC OLIVETTI TIM Req 9 Notifiche e controlli su DPI in scadenza o scaduti (trello https://trello.com/c/rKhrTfDC) - FINE

  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47)
  ALERT_STATUS_CODE_INPROGRESS: "INPROGRESS",
  ALERT_STATUS_CODE_INCHARGE: "INCHARGE",
  ALERT_STATUS_CODE_SOLVED: "SOLVED",
  // A. Stefanutti - COMPLETAMENTO MAN DOWN 3.1.5 Gestione presa in carico allarme (https://trello.com/c/1Ds2Uo47) - FINE

  // ******************************************************************
  // I18N
  // ******************************************************************

  formats: { // https://github.com/yahoo/react-intl/issues/539
    number: {
      USD: {
        style: 'currency',
        currency: 'USD'
      },
      EUR: {
        style: 'currency',
        currency: 'EUR'
      }
    }
  },
}

export const constants = SFConstants;